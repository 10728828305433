import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  parseAndFormatDate,
  bytesToMbytes,
  formatCurrency,
  dateFormat,
} from '@utils/formatters';
import { Card, Label } from '@components/ui';
import RoleChecker from '@components/RoleChecker/RoleChecker';

import CsvDetailedInvoice from './CsvDetailedInvoice/CsvDetailedInvoice';
import PdfDocument from './PdfDetailedInvoice/PdfDetailedInvoice';
import {
  Col,
  Row,
  Skeleton,
  Tag,
  Tabs,
  Button,
  Descriptions,
  message,
  Space,
  Divider,
  Select,
  Tooltip,
  Modal,
} from 'antd';
import {
  CalendarOutlined,
  CodeOutlined,
  DollarCircleOutlined,
  HistoryOutlined,
  SyncOutlined,
  FieldNumberOutlined,
  InfoCircleOutlined,
  ArrowsAltOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useSwr from '@hooks/useSwr';
import AdminActions from '@components/AdminActions/index';
import apiMiddleware from '@services/apiMiddleware';
import Stat from '@components/Stat/Stat';
import Table from '@components/Table';
import EditItemInvoice from '../EditItemInvoice/EditItemInvoice';
import AddItemsInvoice from '../AddItemsInvoice/AddItemsIvoice';
import ContractCard from '@src/components/ContractCard/ContractCard';
import RequestCard from '@src/components/RequestCard/RequestCard';
import rolesConstants from '@utils/rolesConstants';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import useSearchParams from '@src/hooks/useSearchParams';
import ConsumptionCard from '@src/components/ConsumptionCard/ConsumptionCard';
import BackToListing from '@src/components/BackToListing/BackToListing';
import CustomPopover from '@src/components/CustomPopover/CustomPopover';

const { TabPane } = Tabs;

const DetailedInvoice = () => {
  const { t } = useTranslation('invoices');
  const { t: tRequests } = useTranslation('requests');
  const { t: tCustomers } = useTranslation('customers');
  const { customerId, invoiceId } = useParams();
  const { keycloak } = useContext(KeycloakContext);
  const customerIdLogged = keycloak?.idTokenParsed?.customerId;
  const isMyInvoice = customerIdLogged.toString() === customerId;

  const search = useLocation().search;

  const [showAddItemsModal, setShowAddItemsModal] = useState(false);
  const [showEditItem, setShowEditItem] = useState(false);
  const [itemToEdit, setItemToEdit] = useState({});
  const [isConsolidated, setIsConsolidated] = useState(true);
  const [invoiceType, setInvoiceType] = useState('consolidated');
  const [selectedSubInvoice, setSelectedSubInvoice] = useState();
  const [tabItems, setTabItems] = useState('consolidated');
  const [data, setData] = useState();
  const [isSyncHistoryVisible, setIsSyncHistoryVisible] = useState(false);
  const [isSynchronizing, setIsSynchronizing] = useState(false);

  const navigate = useNavigate();

  const { data: dataConsolidated, mutate: mutateDataConsolidated } = useSwr(
    `/service-proxy/broker/${customerId}/invoices/${invoiceId}`,
  );

  const { data: dataDetails } = useSwr(
    `/service-proxy/broker/${customerId}/invoices/${invoiceId}/details`,
  );

  const synchronization = useCallback(
    () =>
      apiMiddleware.post('/service-proxy/omie/sync-invoice', {
        customerId: dataConsolidated?.customer?.id,
        invoiceId: dataConsolidated?.dadosFatura?.idFatura,
      }),
    [dataConsolidated],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'type',
        setState: setInvoiceType,
        inTheFilters: false,
      },
      {
        name: 'invoiceItemsType',
        setState: setTabItems,
        inTheFilters: false,
      },
      {
        name: 'selectedSubInvoice',
        setState: setSelectedSubInvoice,
        inTheFilters: false,
      },
    ],
    [],
  );

  const { handleSetSearchParams } = useSearchParams(paramsAttributes);

  const subInvoicesSelect = useMemo(
    () =>
      dataDetails?.listaSubFaturas?.map(item => {
        const itemName = item?.virtualAccountInfo?.name;
        const itemIdVirtualAccount = item?.virtualAccountInfo?.idVirtualAccount;
        const itemDsPackage = item?.pricePlanInfo?.dsPacote;
        return {
          label: `${itemIdVirtualAccount ?? ''}${
            itemName ? ` - ${itemName}` : ''
          }${itemDsPackage ? ` - ${itemDsPackage}` : ''}`,
          value: item?.virtualAccountInfo?.idVirtualAccount,
        };
      }),
    [dataDetails?.listaSubFaturas],
  );

  useEffect(() => {
    const typeInParams = new URLSearchParams(search).get('type');
    if (typeInParams === null) {
      handleSetSearchParams({ type: 'consolidated' });
    }

    const invoiceItemsTypeInParams = new URLSearchParams(search).get(
      'invoiceItemsType',
    );
    if (typeInParams === invoiceItemsTypeInParams) {
      handleSetSearchParams({ invoiceItemsType: 'consolidated' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoiceType) {
      const tabIsConsolidated = invoiceType === 'consolidated';
      setIsConsolidated(tabIsConsolidated);
      if (tabIsConsolidated) {
        setData(dataConsolidated);
      } else {
        const selectedSubInvoiceInParams = new URLSearchParams(search).get(
          'selectedSubInvoice',
        );
        if (selectedSubInvoiceInParams === null) {
          setSelectedSubInvoice(subInvoicesSelect?.[0]?.value);
        } else {
          setSelectedSubInvoice(Number(selectedSubInvoiceInParams));
        }
      }
    }
  }, [
    invoiceType,
    dataConsolidated,
    subInvoicesSelect,
    handleSetSearchParams,
    search,
  ]);

  useEffect(() => {
    if (isConsolidated) {
      setData(dataConsolidated);
    } else {
      if (selectedSubInvoice) {
        const invoiceSelected = dataDetails?.listaSubFaturas?.find(
          item =>
            item?.virtualAccountInfo?.idVirtualAccount ===
            Number(selectedSubInvoice),
        );
        setData({
          ...invoiceSelected,
          dadosFatura: invoiceSelected?.subFaturaInfo,
        });
      }
    }
  }, [dataConsolidated, dataDetails, isConsolidated, selectedSubInvoice]);

  const invoiceDetails = useMemo(() => {
    if (data) {
      return {
        ...data?.dadosFatura,
        nuQtdItens: data?.nuQtdItens,
        opened: data?.dadosFatura?.idStatusFatura === 'O',
      };
    }
  }, [data]);

  const consumption = useMemo(() => {
    const balance = data?.dadosSaldo;
    const convertMBToB = value => value * 1024 * 1024;
    return {
      totalFranchise:
        balance?.nuFranquiaTotal && convertMBToB(balance?.nuFranquiaTotal),
      franchiseBalance:
        balance?.nusaldoFranquia && convertMBToB(balance?.nusaldoFranquia),
      franchiseConsumption:
        balance?.nuConsumoFranquia && convertMBToB(balance?.nuConsumoFranquia),
      surplusConsumption:
        balance?.nuConsumoExcedente &&
        convertMBToB(balance?.nuConsumoExcedente),
      totalConsumption:
        balance?.nuConsumoTotal && convertMBToB(balance?.nuConsumoTotal),
    };
  }, [data]);

  const listItems = useMemo(() => {
    if (!isConsolidated) {
      return data?.listaItens;
    }
    const typeItem = tabItems === 'loose' ? 'S' : 'N';
    return data?.listaItens?.filter(item => item.blItemEdit === typeItem);
  }, [data, tabItems, isConsolidated]);

  const navigateActionsInvoices = useCallback(
    protocol => {
      message.destroy('protocol-invoice');
      navigate(`/financial/invoices/actions?protocol=${protocol}`);
    },
    [navigate],
  );

  const deleteItem = useCallback(
    itemId =>
      apiMiddleware
        .post(`/service-proxy/broker/invoice-actions`, {
          type: 'REMOVE_ITEM',
          actions: [
            {
              customerId,
              invoiceId,
              itemId,
            },
          ],
        })
        .then(response => {
          if (response.data.code === '200') {
            message.success({
              content: (
                <span>
                  {t('actions.request-success')}
                  <Button
                    onClick={() =>
                      navigateActionsInvoices(response.data.operationProtocol)
                    }
                    type="link"
                    style={{ padding: 3 }}
                  >
                    {t('actions.go-to')}
                  </Button>
                  {t('actions.check-progress')}
                </span>
              ),
              key: 'protocol-invoice',
              duration: 5,
            });
          }
        }),
    [customerId, invoiceId, navigateActionsInvoices, t],
  );

  const handleActions = useCallback(
    async (actionType, record) => {
      const isUpdateUser = actionType === 'UPDATE';
      const itemId = record.idItemFatura;

      if (isUpdateUser) {
        setItemToEdit(record);
        setShowEditItem(true);
      } else {
        try {
          await deleteItem(itemId);
        } catch {
          message.error(t('invoiceItem.msgs.error-to-deleting'));
        }
      }
    },
    [t, deleteItem],
  );

  const itemsColumns = useMemo(
    () => [
      {
        title: t('invoiceItem.id'),
        align: 'center',
        dataIndex: 'idItemFatura',
      },
      { title: t('invoiceItem.description'), dataIndex: 'dsItem' },
      {
        title: t('invoiceItem.quantity'),
        align: 'center',
        dataIndex: 'nuQtdItem',
      },
      {
        title: t('invoiceItem.unitValue'),
        align: 'center',
        dataIndex: 'nuValorUnitario',
        render: value => formatCurrency(Number(value)),
      },
      {
        title: t('invoiceItem.totalValue'),
        align: 'center',
        dataIndex: 'nuValorTotal',
        render: value => formatCurrency(Number(value)),
      },
      ...(tabItems === 'loose' && !isMyInvoice
        ? [
            {
              title: t('invoiceItem.actions'),
              align: 'center',
              dataIndex: 'actions',
              render: (value, record) => {
                const canEdit =
                  record?.blItemEdit === 'S' && record?.idItemFatura;
                if (!canEdit) {
                  return;
                }
                return (
                  <AdminActions
                    alertDelete={t('items-modal.delete.alert')}
                    entityToExclude={record.dsItem}
                    ruleToNotExclude={record.status === 'SYNCHRONIZED'}
                    onEdit={() => handleActions('UPDATE', record)}
                    onDelete={() => handleActions('DELETE', record)}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [handleActions, t, tabItems, isMyInvoice],
  );

  const invoicePeriodFormatted = useMemo(
    () =>
      invoiceDetails?.dataInicio &&
      `${parseAndFormatDate(
        invoiceDetails?.dataInicio,
        'dd-MM-yyyy',
        'dd/MM/yyyy',
      )} ${t('invoice.to')}
      ${parseAndFormatDate(
        invoiceDetails?.dataTermino,
        'dd-MM-yyyy',
        'dd/MM/yyyy',
      )}`,
    [invoiceDetails?.dataInicio, invoiceDetails?.dataTermino, t],
  );

  const invoiceExpirationDateFormatted = useMemo(
    () =>
      parseAndFormatDate(
        invoiceDetails?.dataVencimento,
        'dd-MM-yyyy',
        'dd/MM/yyyy',
      ),
    [invoiceDetails?.dataVencimento],
  );

  const totalTrafficFormatted = useMemo(
    () =>
      invoiceDetails?.nuTrafegoTotal &&
      `${bytesToMbytes(invoiceDetails?.nuTrafegoTotal, 2)} MB`,
    [invoiceDetails?.nuTrafegoTotal],
  );

  const terminalsFormatted = useMemo(
    () =>
      invoiceDetails?.nuTerminais &&
      `${invoiceDetails?.nuTerminais ? invoiceDetails?.nuTerminais : ''}
  (${invoiceDetails?.nuTerminaisUso} ${t('invoice.inUse')})`,
    [invoiceDetails, t],
  );

  const customerDescriptions = useMemo(() => {
    const customerData = data?.customer;
    return [
      {
        label: tCustomers('attributes.id'),
        value: customerData?.id,
      },
      {
        label: tCustomers('attributes.name'),
        value: customerData?.name,
      },
      {
        label: tCustomers('attributes.tradingName'),
        value: customerData?.tradingName,
      },
      {
        label: tCustomers('attributes.cpfCnpj'),
        value: customerData?.cpfCnpj,
      },
    ];
  }, [data?.customer, tCustomers]);

  const handleSelectSubInvoice = useCallback(
    selectedValue => {
      setData(undefined);
      handleSetSearchParams({ selectedSubInvoice: selectedValue });
    },
    [handleSetSearchParams],
  );

  const handleTabsCardChange = useCallback(
    tabKey => {
      setInvoiceType(tabKey);
      handleSetSearchParams({ type: tabKey }, [
        'invoiceItemsType',
        'selectedSubInvoice',
      ]);
    },
    [handleSetSearchParams],
  );

  const handleSynchronization = useCallback(async () => {
    try {
      setIsSynchronizing(true);
      await synchronization();
      mutateDataConsolidated({
        ...dataConsolidated,
        dadosFatura: {
          ...dataConsolidated?.dadosFatura,
          synchronized: true,
        },
      });
      message.success(t('synchronization.msgs.success'));
    } catch {
      message.error(t('synchronization.msgs.error'));
    }
    setIsSynchronizing(false);
  }, [synchronization, dataConsolidated, mutateDataConsolidated, t]);

  const popOverTotalPrice = useCallback(
    (nuValorFatura, record) => {
      const {
        faturamentoMvno,
        nuValorMvnoAlgar,
        nuValorMvnoAllcom,
        nuValorConectividadeLorawan,
        nuValorServicoAgregadoRedeMovel,
      } = record;

      const dataPopover = [
        {
          title: t('searchResultItem.nuValorServicoAgregadoRedeMovel'),
          value: formatCurrency(Number(nuValorServicoAgregadoRedeMovel)),
          show: nuValorServicoAgregadoRedeMovel,
        },
        {
          title: t('searchResultItem.nuValorConectividadeLorawan'),
          value: formatCurrency(Number(nuValorConectividadeLorawan)),
          show: nuValorConectividadeLorawan,
        },
        {
          title: t('searchResultItem.nuValorMvnoAlgar'),
          value: formatCurrency(Number(nuValorMvnoAlgar)),
          show: faturamentoMvno,
        },
        {
          title: t('searchResultItem.nuValorMvnoAllcom'),
          value: formatCurrency(Number(nuValorMvnoAllcom)),
          show: faturamentoMvno,
        },
      ];

      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '22px' }}>
          <p style={{ margin: 0 }}>{t('invoice.value')}</p>
          {dataPopover.filter(item => item.show).length > 0 && (
            <CustomPopover
              title={t('invoice.value')}
              titleValue={formatCurrency(Number(nuValorFatura))}
              titleContent=""
              dataContent={dataPopover.filter(item => item.show)}
            >
              <Button
                type="link"
                style={{
                  padding: 0,
                  margin: 0,
                  marginLeft: 6,
                  fontSize: '18px',
                }}
              >
                <ArrowsAltOutlined />
              </Button>
            </CustomPopover>
          )}
        </div>
      );
    },
    [t],
  );

  return (
    <Card>
      <BackToListing title={t('cards.details')} />
      <RoleChecker role={rolesConstants.VIEW_INVOICES}>
        <Row
          gutter={[16, 16]}
          style={{
            padding: '0 12px',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: 30,
          }}
        >
          <div style={{ flex: 1 }}>
            <Tabs
              type="card"
              size="small"
              defaultActiveKey="consolidated"
              activeKey={invoiceType}
              onChange={handleTabsCardChange}
            >
              <TabPane
                key="consolidated"
                tab={t('invoice.tabs.consolidated')}
              />
              <TabPane key="detailed" tab={t('invoice.tabs.detailed')} />
            </Tabs>
          </div>
          {invoiceDetails?.opened === false && isConsolidated && (
            <RoleChecker role={rolesConstants.DOWNLOAD_INVOICES}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  margin: 2,
                }}
              >
                <PdfDocument customerId={customerId} invoiceId={invoiceId} />
                <CsvDetailedInvoice
                  customerId={customerId}
                  invoiceId={invoiceId}
                />
              </div>
            </RoleChecker>
          )}
        </Row>

        {!isConsolidated && (
          <Card
            style={{
              border: 'none',
              boxShadow: 'none',
              margin: 0,
            }}
          >
            <div style={{ maxWidth: 420, margin: '0 auto', marginTop: -24 }}>
              <div>
                <Label color={'#575962'} htmlFor={'carrier'}>
                  {`${t('invoice.select-sub-invoice')}:`}
                </Label>
              </div>
              <Select
                optionFilterProp="label"
                options={subInvoicesSelect}
                value={selectedSubInvoice && Number(selectedSubInvoice)}
                placeholder={t('invoice.select-sub-invoice-placeholder')}
                allowClear
                showSearch
                loading={!subInvoicesSelect}
                disabled={!subInvoicesSelect || subInvoicesSelect?.length === 0}
                style={{ width: '100%' }}
                onChange={handleSelectSubInvoice}
              />
            </div>
          </Card>
        )}

        <RoleChecker role={rolesConstants.INVOICES_SYNC}>
          <Modal
            title={t('synchronization.sync-history')}
            visible={isSyncHistoryVisible}
            footer={false}
            onCancel={() => setIsSyncHistoryVisible(false)}
            bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
          >
            {dataConsolidated?.syncHistory?.map(
              ({ id, createdAt, createdBy, totalValue, result }) => {
                const resultColor =
                  result === 'SUCCESS' ? 'rgba(0, 166, 80, 1)' : 'red';
                return (
                  <div
                    key={id}
                    style={{
                      border: `1px solid ${resultColor}`,
                      marginBottom: 4,
                      borderRadius: 4,
                      boxShadow: '1px 1px 1px #ccc',
                    }}
                  >
                    <div
                      style={{
                        padding: 8,
                        paddingBottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <p style={{ margin: 0, fontSize: '0.8rem' }}>
                          {createdBy}
                        </p>
                        <p
                          style={{
                            margin: '4px 0',
                            fontWeight: 'bold',
                            opacity: '0.8',
                          }}
                        >
                          {dateFormat(createdAt)}
                        </p>
                      </div>

                      <p
                        style={{
                          margin: 0,
                          fontSize: '1.1rem',
                          opacity: '0.9',
                        }}
                      >
                        <span
                          style={{
                            marginRight: 12,
                            color: 'rgba(0, 166, 80, 1)',
                            fontSize: '1.4rem',
                          }}
                        >
                          <DollarCircleOutlined />
                        </span>

                        {formatCurrency(totalValue)}
                      </p>
                    </div>
                    <div
                      style={{
                        background: resultColor,
                        color: '#ffffff',
                        textAlign: 'center',
                        borderRadius: 2,
                      }}
                    >
                      {t(`synchronization.table-columns.result.${result}`)}
                    </div>
                    <Divider style={{ margin: 0, marginTop: 2 }} />
                  </div>
                );
              },
            )}
          </Modal>
        </RoleChecker>

        {isConsolidated && invoiceDetails ? (
          <Divider orientation="left">
            <Tag
              color={invoiceDetails?.opened ? 'green' : 'volcano'}
              style={{
                fontSize: '0.9rem',
                fontWeight: 'bold',
                padding: '2px 16px',
              }}
            >
              {(invoiceDetails?.opened
                ? t('searchResultItem.opened')
                : t('searchResultItem.closed')
              ).toUpperCase()}
            </Tag>

            <RoleChecker role={rolesConstants.INVOICES_SYNC}>
              {dataConsolidated?.dadosFatura?.idStatusFatura === 'C' && (
                <>
                  <Button
                    type="primary"
                    loading={isSynchronizing}
                    disabled={isSynchronizing}
                    onClick={handleSynchronization}
                  >
                    {dataConsolidated?.syncHistory?.length === 0
                      ? t('synchronization.synchronize')
                      : t('synchronization.resynchronize')}
                  </Button>
                  {dataConsolidated?.syncHistory?.length > 0 && (
                    <Tooltip title={t('synchronization.sync-history')}>
                      <Button
                        icon={<HistoryOutlined />}
                        style={{ margin: '0 4px' }}
                        onClick={() => setIsSyncHistoryVisible(true)}
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </RoleChecker>
          </Divider>
        ) : (
          <Divider style={{ margin: 0, marginBottom: 16 }} />
        )}
        {isConsolidated && (
          <Descriptions
            style={{
              padding: 24,
            }}
          >
            {customerDescriptions?.map(
              ({ label, value }) =>
                value && (
                  <Descriptions.Item key={label} label={label}>
                    {value}
                  </Descriptions.Item>
                ),
            )}
          </Descriptions>
        )}
        <Skeleton loading={!data}>
          {!isConsolidated && (
            <Row gutter={[16, 16]} type={'flex'} style={{ marginBottom: 16 }}>
              <Col lg={12} sm={24} xl={8} xs={24}>
                <Card
                  style={{ height: '100%' }}
                  title={tRequests('cards.request')}
                >
                  <RequestCard data={data?.virtualAccountInfo} />
                </Card>
              </Col>
              <Col lg={12} sm={24} xl={16} xs={24}>
                <ContractCard
                  data={
                    data?.pricePlanInfo && {
                      ...data?.pricePlanInfo,
                      contractName: data?.virtualAccountInfo?.contractName,
                      blBloqueioAutomatico:
                        data?.virtualAccountInfo?.blBloqueioAutomatico,
                    }
                  }
                  virtualPlanId={data?.pricePlanInfo?.virtualPlanId}
                />
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col lg={12} md={12} xl={8} xs={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Stat
                  title={
                    invoiceDetails
                      ? popOverTotalPrice(
                          invoiceDetails?.nuValorFatura,
                          invoiceDetails,
                        )
                      : ''
                  }
                  icon={<DollarCircleOutlined />}
                  value={formatCurrency(invoiceDetails?.nuValorFatura) ?? '-'}
                  loading={!data}
                />
                <Stat
                  title={t('invoice.expirationDate')}
                  icon={<CalendarOutlined />}
                  value={invoiceExpirationDateFormatted ?? '-'}
                  loading={!data}
                />
              </Space>
            </Col>
            <Col lg={12} md={12} xl={8} xs={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Stat
                  title={
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <p style={{ margin: 0 }}>{t('invoice.totalTraffic')}</p>

                        <Tooltip
                          title={t(`searchResultItem.informative.totalTraffic`)}
                        >
                          <Button
                            type="link"
                            icon={<InfoCircleOutlined />}
                            size="sm"
                            style={{ height: '10px' }}
                          />
                        </Tooltip>
                      </div>
                    </>
                  }
                  icon={<SyncOutlined />}
                  value={totalTrafficFormatted ?? '-'}
                  loading={!data}
                  style={{ height: '105px' }}
                />
                <Stat
                  title={t('invoice.terminals')}
                  icon={<CodeOutlined />}
                  value={terminalsFormatted ?? '-'}
                  loading={!data}
                />
              </Space>
            </Col>

            {isConsolidated && (
              <Col lg={12} md={12} xl={8} xs={24}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Stat
                    title={t('invoice.period')}
                    icon={<HistoryOutlined />}
                    value={invoicePeriodFormatted ?? '-'}
                    loading={!data}
                  />
                  <Stat
                    title={t('invoice.nuFatura')}
                    icon={<FieldNumberOutlined />}
                    value={`${invoiceDetails?.idFatura ?? '-'} `}
                    loading={!data}
                  />
                </Space>
              </Col>
            )}

            {!isConsolidated && (
              <Col lg={12} md={12} xl={8} xs={24}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Stat
                    title={t('invoice.nuFatura')}
                    icon={<FieldNumberOutlined />}
                    value={`${invoiceDetails?.idFatura ?? '-'} `}
                    loading={!data}
                  />
                  <Stat
                    title={t('invoice.period')}
                    icon={<HistoryOutlined />}
                    value={invoicePeriodFormatted ?? '-'}
                    loading={!data}
                  />
                </Space>
              </Col>
            )}
          </Row>

          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col lg={24} md={24} xl={24} xs={24}>
              <ConsumptionCard data={consumption} />
            </Col>
          </Row>

          <Table
            title={t('cards.invoiceItems')}
            columns={itemsColumns}
            data={listItems || []}
            loading={!data}
            extraActions={
              <RoleChecker role={rolesConstants.ADD_ITEMS_TO_INVOICE}>
                {isConsolidated && !isMyInvoice && (
                  <Button
                    type="primary"
                    onClick={() => setShowAddItemsModal(true)}
                  >
                    {t('items-modal.new-items')}
                  </Button>
                )}
              </RoleChecker>
            }
            tabsFilters={
              isConsolidated && (
                <Tabs
                  tabBarStyle={{
                    margin: 0,
                    padding: '0 20px',
                    background: '#f9f9f9',
                    borderBottom: '2px solid #808fe671',
                  }}
                  defaultActiveKey="consolidated"
                  activeKey={tabItems}
                  onChange={tabValue =>
                    handleSetSearchParams({ invoiceItemsType: tabValue })
                  }
                >
                  <TabPane
                    key="consolidated"
                    tab={t('invoice.tabs.items-consolidated')}
                  />
                  <TabPane key="loose" tab={t('invoice.tabs.items-loose')} />
                </Tabs>
              )
            }
            tableKey="idItemFatura"
            pagination={false}
          />

          <RoleChecker role={rolesConstants.ADD_ITEMS_TO_INVOICE}>
            <AddItemsInvoice
              visible={showAddItemsModal}
              onClose={() => setShowAddItemsModal(false)}
              invoiceId={invoiceId}
              customerId={customerId}
            />
          </RoleChecker>

          <EditItemInvoice
            modalVisible={showEditItem}
            itemToEdit={itemToEdit}
            invoiceId={invoiceId}
            customerId={customerId}
            onClose={() => setShowEditItem(false)}
          />
        </Skeleton>
      </RoleChecker>
    </Card>
  );
};

export default DetailedInvoice;
