const management = {
  MANAGE_GROUPS: 'manage-groups',
  MANAGE_OPERATORS: 'manage-operators',
  MANAGE_ACCOUNTS: 'manage-accounts',
  MANAGE_CUSTOMERS: 'manage-customers',
  MANAGE_USERS: 'manage-users',
  MANAGE_CANCELLATIONS: 'manage-cancellations',
  MANAGE_PLANS: 'manage-plans',
  MANAGE_PERSONALIZATION: 'manage-personalization',
  MANAGE_TERMS_OF_USE: 'manage-terms-of-use',
  MANAGE_BANNERS: 'manage-banners',
  MANAGE_SMS: 'manage-sms',
  MANAGE_SMS_CONTRACT: 'manage-sms-contract',
  LORAWAN_MANAGE_CONTRACTS: 'lorawan-manage-contracts',
  LORAWAN_MANAGE_REQUESTS: 'lorawan-manage-requests',
};

export const view = {
  MANAGEMENT: 'management',
  ACTIONS_QUEUE: 'actions-queue',
  DASHBOARD: 'dashboard',
  LINES_INFO: 'lines-info',
  LOCATION: 'location',
  VIEW_ACCOUNTS: 'view-accounts',
  VIEW_INVOICES: 'view-invoices',
  VIEW_USERS: 'view-users',
  VIEW_REPORTS: 'view-reports',
  VIEW_PLANS: 'view-plans',
  VIEW_CUSTOMERS: 'view-customers',
  VIEW_OPERATORS: 'view-operators',
  VIEW_CANCELLATIONS: 'view-cancellations',
  VIEW_ERP_CONFIG: 'view-erp-config',
  VIEW_TRANSFERS: 'view-transfers',
  VIEW_OPERATIONAL: 'view-operational',
  VIEW_INVOICES_SYNC_STATUS: 'view-invoices-sync-status',
  ASTRO: 'astro',
  VIEW_DIAGNOSIS: 'view-diagnosis',
  SEND_SMS: 'send-sms',
  SMS_CONTRACT: 'sms-contract',
  SMS_SENDERS: 'sms-senders',
  SMS_REPORTS: 'sms-report',
  SMS_HIRING: 'sms-hiring',
  LORAWAN_VIEW_DASHBOARDS: 'lorawan-view-dashboards',
  LORAWAN_VIEW_DEVICES: 'lorawan-view-devices',
  LORAWAN_VIEW_ACTIONS_QUEUE: 'lorawan-view-actions-queue',
  LORAWAN_VIEW_CONTRACTS: 'lorawan-view-contracts',
  LORAWAN_VIEW_REQUESTS: 'lorawan-view-requests',
  LORAWAN_VIEW_TRANSFERS: 'lorawan-view-transfers',
  LORAWAN_VIEW_REFERRALS: 'lorawan-view-referrals',
};

export const actions = {
  DOWNLOAD_INVOICES: 'download-invoices',
  CREATE_NOTIFICATION: 'create-notification',
  REQUEST_REPORT: 'request-report',
  SIMCARD_RESET: 'simcard-reset',
  NETWORK_RESET: 'network-reset',
  PRIORITY_CHANGE: 'priority-change',
  OPERATOR_RESTRICTION: 'operator-restriction',
  SIMCARD_IMPORT: 'simcard-import',
  NICKNAME_CHANGE: 'nickname-change',
  TRANSFER: 'transfer',
  CANCELLATION: 'cancellation',
  ADD_CREDIT_TO_SIMCARD: 'add-credit-to-simcard',
  ADD_ITEMS_TO_INVOICE: 'add-items-to-invoice',
  MSISDN_EXCHANGE: 'msisdn-exchange',
  NETWORK_BLOCK_UNLOCK: 'network-block-unlock',
  NETWORK_BLOCK: 'network-block',
  NETWORK_UNLOCK: 'network-unlock',
  IMEI_LOCK_UNLOCK: 'imei-lock-unlock',
  IMEI_LOCK: 'imei-lock',
  IMEI_UNLOCK: 'imei-unlock',
  INVOICES_SYNC: 'invoices-sync',
  SUSPENSION: 'suspension',
  WITHDRAWAL: 'withdrawal',
  SUSPENSION_WITHDRAWAL: 'suspension-withdrawal',
  REQUEST_FINANCIAL_REPORT: 'request-financial-report',
  PLASTIC_EXCHANGE: 'plastic-exchange',
  MOT_DEACTIVATION: 'mot-deactivation',
  REQUEST_CUSTOMER_REPORT: 'request-customer-report',
  FINANCIAL_LOCK_UNLOCK: 'financial-lock-unlock',
  ADD_CREDIT_TO_CONTRACT: 'add-credit-to-contract',
  MANUAL_ACTIVATION: 'manual-activation',
  FORCE_REACTIVATION: 'force-reactivation',
  MANAGEMENT_REPORT: 'management-report',
  MANAGEMENT_CANCELLATION_REPORT: 'management-cancellation-report',
  BATCH_ACTIONS: 'batch-actions',
  BROKER_CANCELLATION: 'broker-cancellation',
  BROKER_CANCELLATION_WAIVER: 'broker-cancellation',
  BROKER_SUBSTITUTION: 'broker-cancellation',
  MOT_OPERATOR_CHANGE: 'mot-operator-change',
  TRANSFORM_CUSTOMER_BROKER: 'transform-customer-broker',
  SMS_SEND: 'send-sms',
  LORAWAN_ACTIVATION: 'lorawan-activation',
  LORAWAN_DEACTIVATION: 'lorawan-deactivation',
  LORAWAN_CHANGE_TAGS: 'lorawan-change-tags',
  LORAWAN_CHANGE_DESCRIPTION: 'lorawan-change-description',
  LORAWAN_UPLINK_BLOCK_UNLOCK: 'lorawan-uplink-block-unlock',
  LORAWAN_DOWNLINK_BLOCK_UNLOCK: 'lorawan-downlink-block-unlock',
  LORAWAN_TRANSFERS: 'lorawan-transfers',
  LORAWAN_IMPORT: 'lorawan-import',
  LORAWAN_REFERRALS: 'lorawan-referrals',
};

export const inventColumns = {
  INVENT_CONSUMPTION: 'consumptionTotal',
  INVENT_IMEI: 'imei',
  INVENT_TECHNOLOGY: 'technology',
  INVENT_CUSTOMER_NAME: 'customerName',
  INVENT_AUTOMATIC_BLOCKING: 'automaticBlocking',
  INVENT_IMSI: 'imsi',
  INVENT_OPERATOR: 'operator',
  INVENT_SUB_OPERATOR: 'subOperator',
  INVENT_ONLINE: 'online',
  INVENT_PLAN: 'plan',
  INVENT_LAST_CONNECTION: 'lastConnection',
  INVENT_STATUS: 'status',
  INVENT_PRICE: 'price',
  INVENT_CPF_CNPJ: 'customerDocumentNumber',
  INVENT_NICKNAME: 'nickname',
  INVENT_ICCID: 'iccid',
  INVENT_HOSTNAME: 'hostname',
  INVENT_CONN_OPERATOR: 'connectedOperator',
  INVENT_MSISDN: 'msisdn',
  INVENT_ACTIVATION_DATE: 'activationDate',
  INVENT_APN: 'apn',
  INVENT_IMEI_LOCKED: 'imeiLocked',
  INVENT_ACTIVATION_PRICE: 'activationPrice',
  INVENT_IS_SUSPENDABLE: 'eligibleToSuspension',
  INVENT_FRANCHISE: 'franchise',
  INVENT_CUSTOMER_DOCUMENT_NUMBER: 'customerDocumentNumber',
  INVENT_CONTRACT_ASSET_NUMBER: 'contractAssetNumber',
  INVENT_POSTAL_CODE: 'postalCode',
  INVENT_NETWORK_BLOCK_STATUS: 'networkBlockStatus',
  INVENT_TRADE_IN_MONTHS: 'tradeInMonths',
  INVENT_PRE_ACTIVATION_DAYS: 'preActivationDays',
  INVENT_ACCOUNT_DESC: 'accountDesc',
  INVENT_BROKER_CONTRACT_ID: 'brokerContractId',
  INVENT_BROKER_CONTRACT_DESC: 'brokerContractDesc',
  INVENT_LOYALTY_TIME: 'loyaltyTime',
  INVENT_LOYALTY_TYPE: 'loyaltyType',
  INVENT_SUSPENSION_ELIGIBILITY: 'eligibleToSuspension',
  INVENT_SHARING_TYPE: 'sharingType',
  INVENT_CANCELATION_FEE: 'cancellationDate',
  INVENT_SHARED_CONTRACT_CREDIT: 'sharedCredit',
  INVENT_SIMCARD_CREDIT: 'simcardCredit',
  INVENT_MEGABYTE_EXC_PRICE: 'mbExcPrice',
  INVENT_SHARED_CONTRACT_FRANCHISE: 'sharedFranchise',
  INVENT_CANCELATION_DATE: 'cancellationDate',
  INVENT_PRE_ACTIVATION_END: 'preActivationEnd',
  INVENT_SUSPENSION_START_DATE: 'suspensionStartDate',
  INVENT_ACCOUNT_ID: 'accountId',
  INVENT_ACCOUNT_CREDIT: 'accountCredit',
  INVENT_LAST_UPDATE_STATUS: 'lastStatusUpdate',
  INVENT_LAST_UPDATE: 'lastUpdate',
  INVENT_PARENT_DOCUMENT_NUMBER: 'parentDocumentNumber',
  INVENT_BLOCK_BY_DEFAULT: 'blockedByDefault',
  INVENT_ROAMING: 'roaming',
  INVENT_PENALTY: 'penalty',
  INVENT_ISCA: 'isca',
};

export const inventMoreInfo = {
  INVENT_ASSET_ID: 'assetId',
  INVENT_ACRONYM: 'acronym',
  INVENT_BLOCKING_INITIAL_DATE: 'networkBlockStartDate',
  INVENT_BLOCKING_FINAL_DATE: 'networkBlockEndDate',
  INVENT_OBSERVATION: 'observation',
  INVENT_POSTAL_CODE: 'postalCode',
  INVENT_PARENT_ID: 'parentId',
  INVENT_ID: 'id',
  INVENT_CUSTOMER_ID: 'customerId',
  INVENT_LATITUDE: 'latitude',
  INVENT_CONSUMPTION_CURRENT_DAY: 'consumptionCurrentDay',
  INVENT_LONGITUDE: 'longitude',
  INVENT_CONSUMPTION_PERCENT: 'consumptionPercent',
  INVENT_ID_VIRTUAL_ACCOUNT: 'idVirtualAccount',
  INVENT_STATE: 'state',
  INVENT_CITY: 'city',
  INVENT_DEVICE_NUMBER: 'deviceNumber',
  INVENT_CONSUMPTION_VALIDATED: 'consumptionValidated',
  INVENT_ASSET_PARENT_IOT: 'assetParentIot',
  INVENT_LOCATION_INFO: 'locationInfo',
  INVENT_SIMCARD_CREDIT: 'simcardCredit',
  INVENT_ACCOUNT_CREDIT: 'sharedCredit',
  INVENT_SUSPENSION_INITIAL_DATE: 'suspensionStartDate',
  INVENT_SUSPENSION_FINAL_DATE: 'suspensionEndDate',
  INVENT_LAST_UPDATE: 'lastUpdate',
  INVENT_PARENT_NAME: 'parentName',
};

export const inventory = {
  ...inventColumns,
  ...inventMoreInfo,
};

export const publicApi = {
  API_DEVICE_MANAGEMENT_ACTIVATION: 'api-device-management-activation',
  API_DEVICE_MANAGEMENT_ACTIVATION_VIEW:
    'api-device-management-activation-view',
  API_DEVICE_MANAGEMENT_BLOCK_USE: 'api-device-management-block-use',
  API_DEVICE_MANAGEMENT_UNLOCK_USE: 'api-device-management-unlock-use',
  API_DEVICE_MANAGEMENT_RESET_OTA: 'api-device-management-reset-ota',
  API_DEVICE_MANAGEMENT_RESET_HLR: 'api-device-management-reset-hlr',
  API_DEVICE_MANAGEMENT_OPERATOR_PRIORITY:
    'api-device-management-operator-priority',
  API_DEVICE_MANAGEMENT_RESET: 'api-device-management-reset',
  API_DEVICE_MANAGEMENT_RESET_OTA_VIEW: 'api-device-management-reset-ota-view',
  API_DEVICE_MANAGEMENT_RESET_HLR_VIEW: 'api-device-management-reset-hlr-view',
  API_DEVICE_MANAGEMENT_CHANGE_SIMCARD: 'api-device-management-change-simcard',
  API_DEVICE_MANAGEMENT_CHANGE_SIMCARD_VIEW:
    'api-device-management-change-simcard-view',
  API_DEVICE_MANAGEMENT_PROVISIONAL_SUSPENSION:
    'api-device-management-provisional-suspension',
  API_DEVICE_MANAGEMENT_PROVISIONAL_SUSPENSION_VIEW:
    'api-device-management-provisional-suspension-view',
  API_DEVICE_MANAGEMENT_CONTRACTS_VIEW: 'api-device-management-contracts-view',
  API_DEVICE_MANAGEMENT_OPERATORS_VIEW: 'api-device-management-operators-view',
  API_DEVICE_MANAGEMENT_LIST_RESET_OTA: 'api-device-management-list-reset-ota',
  API_DEVICE_MANAGEMENT_LIST_RESET_HLR: 'api-device-management-list-reset-hlr',
  API_DEVICE_MANAGEMENT_LIST_RESET: 'api-device-management-list-reset',
  API_DEVICE_MANAGEMENT_ACTIONS_VIEW: 'api-device-management-actions-view',
  API_NETWORKS_SMS: 'api-networks-sms',
  API_BROKER_TRANSFER: 'api-broker-transfer',
  API_BROKER_CUSTOMERS_CREATE: 'api-broker-customers-create',
  API_BROKER_ORDER_CREATE: 'api-broker-order-create',
  API_BROKER_PLANS_CREATE: 'api-broker-plans-create',
  API_BROKER_VIRTUAL_ACCOUNTS_INVOICES_VIEW:
    'api-broker-virtual-accounts-invoices-view',
  API_BROKER_VIRTUAL_ACCOUNTS_INVOICES_USAGE_VIEW:
    'api-broker-virtual-accounts-invoices-usage-view',
  API_BROKER_PLANS_VIEW: 'api-broker-plans-view',
  API_BROKER_QUOTA_VIEW: 'api-broker-quota-view',
  API_BROKER_TRANSFER_VIEW: 'api-broker-transfer-view',
  API_BROKER_CUSTOMER_VIEW: 'api-broker-customer-view',
  API_BROKER_ORDER_VIEW: 'api-broker-order-view',
  API_BROKER_DEVICES_VIEW: 'api-broker-devices-view',
  API_BROKER_SAVE_ITEM_INVOICE: 'api-broker-save-item-invoice',
  API_BROKER_ITEM_INVOICE_VIEW: 'api-broker-item-invoice-view',
  API_BROKER_ITEM_INVOICE_DELETE: 'api-broker-item-invoice-delete',
  API_BROKER_UPDATE_ITEM_INVOICE: 'api-broker-update-item-invoice',
  API_BROKER_INVOICES_SUMMARY: 'api-broker-invoices-summary',
  API_BROKER_CUSTOMERS_TYPES_VIEW: 'api-broker-customers-types-view',
  API_BROKER_DEVICES_QUANTITY_VIEW: 'api-broker-devices-quantity-view',
  API_REPORT_GET_VIEW: 'api-report-get-view',
  API_REPORT_VIEW: 'api-report-view',
  API_BROKER_VIRTUAL: 'api-broker-virtual',
  API_DEVICE_REPORT: 'api-device-report',
  API_BROKER_VIRTUAL_ACCOUNTS_CREATE: 'api-broker-virtual-accounts-create',
  API_USER_ACCOUNT_VIEW: 'api-user-account-view',
  API_USER_GROUPS_VIEW: 'api-user-groups-view',
  API_OMIE_INVOICE_VIEW: 'api-omie-invoice-view',
  API_ADMIN_REQUEST_QUANTITY_SUMMARY: 'api-admin-request-quantity-summary',
  API_ADMIN_REQUEST_QUANTITY: 'api-admin-request-quantity',
  API_ADMIN_REQUEST_DETAILS: 'api-admin-request-details',
  API_REPORT_REQUEST_CONSUMERS: 'api-report-request-consumers',
  API_REPORT_MANAGEMENT: 'api-report-management',
  API_BROKER_ADD_CONTRACT_CREDIT: 'api-broker-add-contract-credit',
  API_SESSION_HISTORY_VIEW: 'api-session-history-view',
  API_BROKER_UNLOCK_FINANCIAL: 'api-broker-unlock-financial',
  API_BROKER_BLOCK_FINANCIAL: 'api-broker-block-financial',
  API_BROKER_ADD_CREDIT: 'api-broker-add-credit',
  API_BROKER_CHANGE_MSISDN: 'api-broker-change-msisdn',
  API_DEVICE_MANAGEMENT_REPROGRAM_SUBSCRIBER:
    'api-device-management-reprogram-subscriber',
  API_REQUEST_SMS: 'api-request-sms',
  API_REQUEST_SMS_VIEW: 'api-request-sms-view',
  API_SMS_VIEW: 'api-sms-view',
  API_BROKER_OPERATIONS_VIEW: 'api-broker-operations-view',
  API_BROKER_SUBSTITUTION: 'api-broker-substitution',
  API_BROKER_SUSPENSION: 'api-broker-suspension',
  API_BROKER_SUSPENSION_CANCELLATION: 'api-broker-suspension-cancellation',
  API_BROKER_SUSPENSION_REVERSE: 'api-broker-suspension-reverse',
  API_BROKER_SUSPENSION_WITHDRAWAL: 'api-broker-suspension-withdrawal',
  API_REPORT_INVOICES: 'api-report-invoices',
};

export default {
  ...management,
  ...view,
  ...actions,
  ...inventory,
  ...publicApi,
};
