import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '@utils/formatters';
import { Button, Descriptions, Popover, Segmented, Tag } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import * as S from './styles';

const ReferralListItem = ({ item }) => {
  const { t } = useTranslation('lorawan');
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState('filter');

  const toggleOpen = useCallback(() => setIsOpen(crrOpen => !crrOpen), []);

  const segmentedOptions = useMemo(
    () =>
      ['filter', 'connection'].map(opt => ({
        label: t(opt),
        value: opt,
      })),
    [t],
  );

  const renderItems = useCallback(items => {
    if (!items) return undefined;
    const firstFourItems = items.slice(0, 4).join(', ');
    if (items.length <= 4) {
      return firstFourItems;
    }
    const otherItems = items.slice(4).join(', ');
    return (
      <div>
        {firstFourItems}
        <Popover
          content={
            <div style={{ maxWidth: 300, width: '100%' }}>{otherItems}</div>
          }
        >
          <Button
            size="small"
            icon={<EllipsisOutlined />}
            style={{ marginLeft: 8 }}
          />
        </Popover>
      </div>
    );
  }, []);

  const renderTags = useCallback(
    tags =>
      tags && (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {tags?.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </div>
      ),
    [],
  );

  const treatedFilter = useMemo(() => {
    const { gateways, ...filter } = item?.filter;
    return {
      ...filter,
      applications: renderItems(filter?.applications),
      devices: renderItems(filter?.devices),
      types: renderTags(filter?.types),
      tags: renderTags(filter?.tags),
    };
  }, [item, renderItems, renderTags]);

  const treatedConnection = useMemo(() => {
    const { id, clientId, filter, ...connection } = item?.connection;
    return {
      ...connection,
      type: connection?.type === 'http2' ? 'HTTP' : connection?.type,
    };
  }, [item]);

  const renderContentDescriptions = useMemo(() => {
    const selected =
      itemSelected === 'filter' ? treatedFilter : treatedConnection;

    return Object.entries(selected)?.map(([label, value]) => ({
      label: t(label),
      value,
    }));
  }, [itemSelected, treatedFilter, treatedConnection, t]);

  return (
    <S.ListItemContainer>
      <S.ListItemHeader onClick={toggleOpen} isOpen={isOpen}>
        <S.ListItemHeaderTitle>
          <span>{item?.connection?.description}</span>
          <span>{dateFormat(item?.createdAt)}</span>
        </S.ListItemHeaderTitle>
        <S.ArrowIcon isOpen={isOpen} />
      </S.ListItemHeader>
      {isOpen && (
        <S.ListItemContent>
          <Segmented
            size="small"
            default={false}
            options={segmentedOptions}
            onChange={value => setItemSelected(value)}
          />
          <Descriptions column={itemSelected === 'filter' ? 1 : 2}>
            {renderContentDescriptions?.map(
              ({ label, value }) =>
                value && (
                  <Descriptions.Item key={label} label={label}>
                    {value}
                  </Descriptions.Item>
                ),
            )}
          </Descriptions>
        </S.ListItemContent>
      )}
    </S.ListItemContainer>
  );
};

export default memo(ReferralListItem);
