import React, { useContext, useMemo } from 'react';

import { Row, Descriptions, Skeleton, Tag, Tooltip, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseAndFormatDate } from '@src/utils/formatters';

import { Card } from '@components/ui';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';

const ContractCard = ({ data, virtualPlanId }) => {
  const { t } = useTranslation('requests');
  const navigate = useNavigate();

  const { keycloak } = useContext(KeycloakContext);

  const customerType = keycloak?.idTokenParsed?.customerType;
  const isEndCustomer = customerType === 'CUSTOMER';

  const negociatedValueFormatted = useMemo(
    () =>
      data?.nuValorNegociado &&
      `R$ ${parseFloat((data?.nuValorNegociado).replace(',', '.')).toFixed(2)}`,
    [data?.nuValorNegociado],
  );

  const dtAtivacaoPlanFormatted = useMemo(
    () => parseAndFormatDate(data?.dtAtivacao, 'yyyyMMdd', 'dd/MM/yyyy'),
    [data?.dtAtivacao],
  );

  const chargeDifPerOperatorFormatted = useMemo(
    () =>
      data?.blCobrancaDifOperadora === 'S'
        ? t('plan.chargeTypes.perOperator')
        : t('plan.chargeTypes.fixed'),
    [data?.blCobrancaDifOperadora, t],
  );

  const sharedFranchiseFormatted = useMemo(
    () =>
      data?.nuFranquiaCompartilhadaMbytes
        ? `${data?.nuFranquiaCompartilhadaMbytes} MB`
        : '-',
    [data?.nuFranquiaCompartilhadaMbytes],
  );

  const negociatedFranchiseFormatted = useMemo(
    () =>
      data?.nuFranquiaNegociadaMbytes
        ? `${data?.nuFranquiaNegociadaMbytes} MB`
        : '-',
    [data?.nuFranquiaNegociadaMbytes],
  );

  const mbExcChargeFormatted = useMemo(
    () =>
      data?.nuValorMBExcedente &&
      `R$ ${parseFloat((data?.nuValorMBExcedente).replace(',', '.')).toFixed(
        2,
      )}`,
    [data?.nuValorMBExcedente],
  );

  const activationChargeFormatted = useMemo(
    () =>
      data?.nuValorAtivacaoTerminal &&
      `R$ ${parseFloat(
        (data?.nuValorAtivacaoTerminal).replace(',', '.'),
      ).toFixed(2)}`,
    [data?.nuValorAtivacaoTerminal],
  );

  const planTypeFormatted = useMemo(() => {
    if (data?.blCobrancaPorUso === 'S') {
      return t('plan.type.payPerUse');
    }

    switch (data?.idTipoCompartilhamento) {
      case 1:
        return t('plan.type.sharedWithout');
      case 2:
        return t('plan.type.sharedTotal');
      case 3:
        return t('plan.type.sharedMixed');
      default:
        return '';
    }
  }, [data?.idTipoCompartilhamento, data?.blCobrancaPorUso, t]);

  const handleAutomaticBlocking = useMemo(() => {
    const [color, text] =
      data?.blBloqueioAutomatico === 1
        ? ['green', 'activated']
        : ['red', 'disabled'];
    return (
      <Tag color={color}>
        {t(`plan.automaticBlocking.${text}`).toUpperCase()}
      </Tag>
    );
  }, [t, data?.blBloqueioAutomatico]);

  const planItemsDescriptions = useMemo(
    () => [
      {
        label: t('plan.description'),
        value:
          virtualPlanId && !isEndCustomer ? (
            <Tooltip title={t('view-contract')}>
              <Button
                style={{
                  margin: 0,
                  padding: 0,
                  height: '100%',
                }}
                type="link"
                onClick={() =>
                  navigate(`/financial/contracts/${virtualPlanId}`)
                }
              >
                {data?.contractName}
              </Button>
            </Tooltip>
          ) : (
            data?.contractName
          ),
        showItem: data?.contractName,
      },
      {
        label: t('plan.planType'),
        value: planTypeFormatted,
        showItem: planTypeFormatted,
      },
      {
        label: t('plan.creation-date'),
        value: dtAtivacaoPlanFormatted,
        showItem: dtAtivacaoPlanFormatted,
      },
      {
        label: t('plan.negociatedFranchise'),
        value: negociatedFranchiseFormatted,
        showItem: negociatedFranchiseFormatted,
      },
      {
        label: t('plan.activationCharge'),
        value: activationChargeFormatted,
        showItem: activationChargeFormatted,
      },
      {
        label: t('plan.automaticBlocking.title'),
        value: handleAutomaticBlocking,
        showItem: handleAutomaticBlocking,
      },
      {
        label: t('plan.chargeType'),
        value: chargeDifPerOperatorFormatted,
        showItem: data?.blCobrancaPorUso === 'N',
      },
      {
        label: t('plan.negociatedValue'),
        value: negociatedValueFormatted,
        showItem: data?.blCobrancaDifOperadora === 'N',
      },
      {
        label: t('plan.sharedFranchise'),
        value: sharedFranchiseFormatted,
        showItem: data?.idTipoCompartilhamento === 3,
      },
      {
        label: t('plan.mbExcCharge'),
        value: mbExcChargeFormatted,
        showItem: data?.blCobrancaPorUso === 'N',
      },
    ],
    [
      activationChargeFormatted,
      chargeDifPerOperatorFormatted,
      data,
      dtAtivacaoPlanFormatted,
      handleAutomaticBlocking,
      mbExcChargeFormatted,
      negociatedFranchiseFormatted,
      negociatedValueFormatted,
      planTypeFormatted,
      sharedFranchiseFormatted,
      navigate,
      virtualPlanId,
      isEndCustomer,
      t,
    ],
  );

  return (
    <Card style={{ height: '100%' }} title={t('columns.planDesc')}>
      <Row>
        <Skeleton loading={!data}>
          <Descriptions column={{ xl: 2, lg: 1, md: 2, sm: 1, xs: 1 }}>
            {planItemsDescriptions?.map(
              ({ label, value, showItem }) =>
                showItem && (
                  <Descriptions.Item key={label} label={label}>
                    {value}
                  </Descriptions.Item>
                ),
            )}
          </Descriptions>
        </Skeleton>
      </Row>
    </Card>
  );
};

export default ContractCard;
