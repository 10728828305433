import React from 'react';
import { Form, Row, Col, Radio, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

const ConnectionStep = ({ form }) => {
  const { t } = useTranslation('lorawan');
  const connectionType = Form.useWatch('type', form);

  return (
    <Form
      colon={false}
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      scrollToFirstError
      layout="vertical"
      initialValues={{ type: 'mqtt' }}
    >
      <Row gutter={[8, 8]}>
        <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
          <Item name="type">
            <Radio.Group defaultValue="mqtt">
              <Radio.Button value="mqtt">MQTT</Radio.Button>
              <Radio.Button value="http2">HTTP</Radio.Button>
            </Radio.Group>
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label={t('description')}
            name="description"
            rules={[
              {
                required: true,
                message: t('fieldIsRequired'),
              },
            ]}
          >
            <Input allowClear />
          </Item>
        </Col>
        {connectionType === 'mqtt' ? (
          <>
            <Col span={24}>
              <Item
                label="URI"
                name="uri"
                rules={[
                  {
                    required: true,
                    message: t('fieldIsRequired'),
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder="mqtt://user:pass@example.com:1883"
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item
                label="Network server publish topic"
                name="topicUp"
                rules={[
                  {
                    required: true,
                    message: t('fieldIsRequired'),
                  },
                ]}
              >
                <Input allowClear />
              </Item>
            </Col>
          </>
        ) : (
          <Col span={24}>
            <Item
              label="URL"
              name="url"
              rules={[
                {
                  required: true,
                  message: t('fieldIsRequired'),
                },
              ]}
            >
              <Input allowClear placeholder="http://example.com" />
            </Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default ConnectionStep;
