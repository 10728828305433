import React, { useMemo, useState } from 'react';
import { Row, Space, Col, Tooltip, Divider, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Card } from '@components/ui';
import Stat from '@components/Stat/Stat';
import { useTranslation } from 'react-i18next';
import { bytesFormat } from '@utils/formatters';
import './styles.less';

const ConsumptionCard = ({ data }) => {
  const { t } = useTranslation('general');

  const [hasConsumptionProgress, setHasConsumptionProgress] = useState(false);

  const consumeProgress = useMemo(() => {
    const franchise = (100 * data?.franchiseConsumption) / data?.totalFranchise;

    setHasConsumptionProgress(!!franchise);
    return {
      franchiseConsumption: franchise,
      availableFranchise: 100 - franchise,
    };
  }, [data]);

  const consumptionStates = useMemo(
    () => [
      { title: 'totalFranchise', hasInformative: true },
      { title: 'franchiseConsumption', hasInformative: true },
      { title: 'surplusConsumption', hasInformative: false },
      { title: 'totalConsumption', hasInformative: false },
    ],
    [],
  );

  return (
    <Card
      className="consumption-card"
      title={t('components.consumption-card.title')}
    >
      <Row gutter={[16, 16]}>
        {consumptionStates?.map(state => (
          <Col key={state.title} lg={12} md={12} xl={6} xs={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Stat
                className={`consumption-stat ${state.title}`}
                title={
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p>{t(`components.consumption-card.${state.title}`)}</p>
                      {state.hasInformative && (
                        <Tooltip
                          title={t(
                            `components.consumption-card.informative.${state.title}`,
                          )}
                        >
                          <Button
                            type="link"
                            icon={<InfoCircleOutlined />}
                            size="sm"
                          />
                        </Tooltip>
                      )}
                    </div>
                  </>
                }
                value={bytesFormat(data?.[state.title])}
                loading={!data?.[state.title]}
              />
            </Space>
          </Col>
        ))}
        {hasConsumptionProgress && (
          <>
            <Divider />
            <footer
              className="consumption-card-footer"
              style={{
                justifyContent: !data?.updatedAt ? 'center' : 'space-between',
              }}
            >
              <div className="consumption-card-progress-bar-container">
                <span className="consumption-card-progress-bar-percentage-value">
                  {`${consumeProgress.franchiseConsumption.toFixed()}%`}
                </span>
                <div className="consumption-card-progress-bar-content">
                  <Tooltip title={bytesFormat(data?.franchiseConsumption)}>
                    <div
                      className="consumption-card-progress-bar-content-main"
                      style={{
                        width: `${consumeProgress.franchiseConsumption}%`,
                        background:
                          consumeProgress.franchiseConsumption === 100
                            ? 'var(--primary-color)'
                            : 'cornflowerblue',
                        borderRadius:
                          data?.surplusConsumption > 0 ? '80px 0 0 80px' : 4,
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={bytesFormat(
                      data?.totalFranchise - data?.franchiseConsumption,
                    )}
                  >
                    <div
                      style={{
                        width: `${consumeProgress.availableFranchise}%`,
                        height: '100%',
                      }}
                    />
                  </Tooltip>

                  {data?.totalConsumption > data?.totalFranchise && (
                    <Tooltip title={bytesFormat(data?.surplusConsumption)}>
                      <div className="consumption-card-progress-bar-content-surplus" />
                    </Tooltip>
                  )}
                </div>
              </div>
            </footer>
          </>
        )}
      </Row>
    </Card>
  );
};

export default ConsumptionCard;
