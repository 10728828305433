import Table from '@components/Table';
import RequestReport from '@src/components/RequestReport/RequestReport';
import useSwr from '@src/hooks/useSwr';
import { dateFormat, formatCurrency } from '@src/utils/formatters';
import { ConfigProvider, Col, Select, Tooltip } from 'antd';
import rolesConstants from '@src/utils/rolesConstants';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SmsContracsRequestReport from './SmsContracsRequestReport/index';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import useSearchParams from '@src/hooks/useSearchParams';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import { getAntLocale } from '@src/utils/locales';
import { Filters, FiltersCard } from '@src/components/Filters/index';
import { Label } from '@src/components/ui/index';
import SessionContext from '@src/store/SessionContext/SessionContext';

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 20,
  pageSize: 20,
  pageSizeOptions: [20],
  total: 1,
};

const SmsHiringAudit = () => {
  const [showRequestReportSms, setShowRequestReportSms] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [customerId, setCustomerId] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const {
    customer: { customersOptions },
  } = useContext(SessionContext);

  const { keycloak } = useContext(KeycloakContext);
  const parentId = keycloak.idTokenParsed?.customerId;
  const isAllcom = parentId === 10000;

  const {
    t: translate,
    i18n: { language },
  } = useTranslation(['sms-send']);

  const params = useMemo(
    () => ({
      page: pagination?.current - 1,
      perPage: pagination?.pageSize,
      startDate,
      endDate,
      customerId,
    }),
    [customerId, endDate, pagination, startDate],
  );

  const { data: receivable } = useSwr(
    isAllcom ? '/service-proxy/sms-hiring/receivable' : null,
    {
      ...params,
    },
    { refreshInterval: 10 * 2000 },
  );

  const { data: payable } = useSwr(
    !isAllcom ? '/service-proxy/sms-hiring/payable' : null,
    {
      ...params,
    },
    { refreshInterval: 10 * 2000 },
  );

  const data = useMemo(
    () => (isAllcom ? receivable : payable),
    [isAllcom, payable, receivable],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'customer',
        setState: setCustomerId,
        inTheFilters: true,
      },
      {
        name: 'startDate',
        setState: setStartDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      handleSetSearchParams({
        startDate: initialDate,
        endDate: endDate,
      });
    },
    [handleSetSearchParams],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: isAllcom,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: translate('table.customerName'),
        item: (
          <Select
            loading={!customersOptions}
            disabled={!customersOptions}
            optionFilterProp={'label'}
            options={customersOptions}
            placeholder={translate('table.customerName')}
            style={{ width: '100%' }}
            value={customerId ? Number(customerId) : undefined}
            allowClear
            showSearch
            onChange={value => handleSetSearchParams({ customer: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: translate('table.hiringDate'),
        item: (
          <ConfigProvider locale={getAntLocale(language)}>
            <QuickRangePicker
              onChange={handleDateChange}
              defaultValue={
                startDate
                  ? [moment(startDate, 'YYYYMMDD'), moment(endDate, 'YYYYMMDD')]
                  : undefined
              }
              style={{ width: '100%' }}
            />
          </ConfigProvider>
        ),
      },
    ],
    [
      translate,
      startDate,
      endDate,
      handleDateChange,
      language,
      customersOptions,
      customerId,
      handleSetSearchParams,
      isAllcom,
    ],
  );

  const columns = [
    ...(isAllcom
      ? [
          {
            title: translate('table.customerName'),
            dataIndex: 'customerName',
            key: 'customerName',
            render: (_, record) => record?.customer?.customerName,
          },
        ]
      : []),
    {
      title: translate('table.description'),
      dataIndex: 'description',
      key: 'description',
      render: (_, record) =>
        record?.contract?.description && record?.contract?.description,
    },
    {
      title: translate('table.type'),
      dataIndex: 'type',
      key: 'type',
      render: (_, record) =>
        record?.contract?.type &&
        translate(`status-type.${record?.contract?.type}`),
    },
    {
      title: translate('table.franchise'),
      dataIndex: 'franchise',
      key: 'franchise',
      render: franchise => franchise && `${franchise} SMS`,
    },
    {
      title: translate('table.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price, record) =>
        price && (
          <>
            {record.contract?.type === 'PAY_PER_USE'
              ? `${formatCurrency(Number(price))} / SMS`
              : formatCurrency(Number(price))}
          </>
        ),
    },
    {
      title: translate('table.hiringDate'),
      dataIndex: 'hiringDate',
      key: 'hiringDate',
      render: hiringDate => hiringDate && dateFormat(hiringDate),
    },
    {
      title: translate('table.dtUpdated'),
      dataIndex: 'dtUpdated',
      key: 'dtUpdated',
      render: dtUpdated => dtUpdated && dateFormat(dtUpdated),
    },
    {
      title: translate('table.username'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: translate('table.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: translate('table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        if (status === 'CANCELED') {
          return (
            <div style={{ display: 'flex', gridGap: '5px' }}>
              <>{translate('status-type.CANCELED')}</>
              {record?.dtCanceled && (
                <Tooltip
                  title={moment(record?.dtCanceled).format('DD/MM/YYYY')}
                >
                  <p style={{ color: 'red', padding: 0, maring: 0 }}>
                    <CalendarOutlined />
                  </p>
                </Tooltip>
              )}
            </div>
          );
        }
        return translate(`status-type.${status}`);
      },
    },
  ];

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['page', 'linesPerPage'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [customerId, startDate, endDate]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  return (
    <>
      <SmsContracsRequestReport
        visible={showRequestReportSms}
        onClose={() => setShowRequestReportSms(false)}
      />
      <Table
        title={
          <>
            {translate('title.audit')}
            {data?.content && (
              <span
                style={{ fontSize: '0.9rem', opacity: 0.6, marginRight: 4 }}
              >
                {` (${data?.content?.length} ${translate('of', {
                  total: pagination.total,
                })})`}
              </span>
            )}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        columns={columns}
        data={data?.content}
        loading={!data?.content}
        tableKey="sms-send"
        pagination={pagination}
        onChange={handleTableChange}
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
        extraActions={
          <>
            <RequestReport
              items={[
                {
                  role: rolesConstants.REQUEST_REPORT,
                  path: '/service-proxy/sms-hiring/report',
                  type: 'sms-hiring',
                  secondaryDescription: true,
                  action: () => setShowRequestReportSms(true),
                },
              ]}
            />
          </>
        }
      />
    </>
  );
};

export default SmsHiringAudit;
