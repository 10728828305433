import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Descriptions, Row, Skeleton } from 'antd';
import {
  formatCpfCnpj,
  formatCurrency,
  parseAndFormatDate,
} from '@src/utils/formatters';

function RequestCard({ data, extraDescriptions }) {
  const { t } = useTranslation('requests');

  const cnpjFormatted = useMemo(
    () => (data?.nuCNPJ ? formatCpfCnpj(data.nuCNPJ) : ''),
    [data?.nuCNPJ],
  );

  const dtAtivacaoAccountFormatted = useMemo(
    () =>
      parseAndFormatDate(
        data?.dtAtivacao,
        'yyyyMMddHHmmss',
        'dd/MM/yyyy HH:mm',
      ),
    [data?.dtAtivacao],
  );

  const dtDesativacaoAccountFormatted = useMemo(
    () =>
      parseAndFormatDate(
        data?.dtDesativacao,
        'yyyyMMddHHmmss',
        'dd/MM/yyyy HH:mm',
      ),
    [data?.dtDesativacao],
  );

  const requestItemsDescriptions = useMemo(() => {
    const descriptions = [
      {
        label: t('request.name'),
        value: data?.name,
        showItem: data?.name,
      },
      {
        label: t('request.corporateName'),
        value: data?.nmRazaoSocial,
        showItem: data?.nmRazaoSocial,
      },
      {
        label: t('request.apn'),
        value: data?.apn,
        showItem: data?.apn,
      },
      {
        label: t('request.documentNumber'),
        value: cnpjFormatted,
        showItem: cnpjFormatted,
      },
      {
        label: t('request.release-date-of'),
        value: dtAtivacaoAccountFormatted,
        showItem: dtAtivacaoAccountFormatted,
      },
      {
        label: t('request.desactivationDate'),
        value: dtDesativacaoAccountFormatted,
        showItem: dtDesativacaoAccountFormatted,
      },
      {
        label: t('modal.configStep.cancellation.type-of-penalty'),
        value: t(
          `modal.configStep.cancellation.types-of-penalty.${data?.tpCobrancaFidelizacao}`,
        ),
        showItem: true,
      },
      {
        label: t('modal.configStep.cancellation.penalty-amount'),
        value: formatCurrency(parseFloat(data?.nuValorMulta.replace(',', '.'))),
        showItem: data?.tpCobrancaFidelizacao === 1,
      },
      {
        label: t('modal.configStep.cancellation.loyalty-time'),
        value: data?.nuPrazoFidelizacao,
        showItem: data?.tpCobrancaFidelizacao === 2,
      },
    ];

    if (extraDescriptions) {
      descriptions.push(...extraDescriptions);
    }

    return descriptions;
  }, [
    t,
    cnpjFormatted,
    data,
    dtAtivacaoAccountFormatted,
    dtDesativacaoAccountFormatted,
    extraDescriptions,
  ]);

  return (
    <Row>
      <Skeleton loading={!data}>
        <Descriptions column={1}>
          {requestItemsDescriptions?.map(
            ({ label, value, showItem }) =>
              showItem &&
              value && (
                <Descriptions.Item key={label} label={label}>
                  {value}
                </Descriptions.Item>
              ),
          )}
        </Descriptions>
      </Skeleton>
    </Row>
  );
}

export default RequestCard;
