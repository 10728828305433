import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { Button, Input, Modal, Tooltip, Col, Tabs, message, Tag } from 'antd';
import { Filters, FiltersCard } from '@components/Filters';
import { Label } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import NewContractModal from './NewContractModal/NewContractModal';
import {
  ExclamationCircleOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import RoleChecker, { useCheckRole } from '@components/RoleChecker/RoleChecker';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import useSearchParams from '@src/hooks/useSearchParams';
import SessionContext from '@src/store/SessionContext/SessionContext';
import apiMiddleware from '@src/services/apiMiddleware';
import { dateFormat } from '@src/utils/formatters';
import rolesConstants from '@utils/rolesConstants';
import SelectCustomers from '@src/components/SelectCustomers/index';

const { confirm } = Modal;
const { Search } = Input;
const { TabPane } = Tabs;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const Contracts = () => {
  const { t } = useTranslation(['contracts', 'attributes', 'device-actions']);
  const { hasCustomersRoles } = useContext(SessionContext);

  const navigate = useNavigate();
  const searchLocation = useLocation().search;
  const canManage = useCheckRole({ role: rolesConstants.MANAGE_PLANS });

  const [modalVisible, setModalVisible] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [selectedClient, setSelectedClient] = useState();
  const [description, setDescription] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [externalId, setExternalId] = useState('');
  const [externalIdValue, setExternalIdValue] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGINATION.current);
  const [linesPerPage, setLinesPerPage] = useState(DEFAULT_PAGINATION.pageSize);
  const [listingType, setListingType] = useState('active');

  const { data: contracts, mutate } = useSwr(
    `/service-proxy/broker/virtual-plan`,
    {
      page: pagination?.current - 1,
      linesPerPage: pagination?.pageSize,
      customerId: selectedClient,
      name: description,
      externalId,
    },
  );

  const { data: archivedContracts, mutate: mutateArchivedContracts } = useSwr(
    `/service-proxy/broker/virtual-plan/archived`,
    {
      page: pagination?.current - 1,
      linesPerPage: pagination?.pageSize,
      customerId: selectedClient,
      name: description,
      externalId,
    },
  );

  const data = useMemo(
    () => (listingType === 'active' ? contracts : archivedContracts),
    [archivedContracts, contracts, listingType],
  );

  const fileTheContract = useCallback(
    id =>
      apiMiddleware.patch(`/service-proxy/broker/virtual-plan/${id}/archive`),
    [],
  );

  const handleMutateContract = useCallback(
    contract => {
      mutate({
        ...data,
        content: [contract, ...data.content].slice(0, 10),
      });
    },
    [data, mutate],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'customerId',
        setState: setSelectedClient,
        inTheFilters: true,
      },
      {
        name: 'externalId',
        setState: setExternalId,
        setStateValue: setExternalIdValue,
        inTheFilters: true,
      },
      {
        name: 'description',
        setState: setDescription,
        setStateValue: setDescriptionValue,
        inTheFilters: true,
      },
      {
        name: 'page',
        setState: setPage,
        inTheFilters: false,
      },
      {
        name: 'linesPerPage',
        setState: setLinesPerPage,
        inTheFilters: false,
      },
      {
        name: 'listingType',
        setState: setListingType,
        inTheFilters: false,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    const listingTypedInParams = new URLSearchParams(searchLocation).get(
      'listingType',
    );
    if (listingTypedInParams === null) {
      handleSetSearchParams({ listingType: 'active' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSetSearchParams]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [selectedClient, externalId, description, linesPerPage, listingType]);

  useEffect(() => {
    if (page && linesPerPage) {
      setPagination(oldPagination => ({
        ...oldPagination,
        current: parseInt(page),
        pageSize: parseInt(linesPerPage),
      }));
    }
  }, [page, linesPerPage]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data, handleSetSearchParams]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['page', 'linesPerPage'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  const showArchiveConfirmation = useCallback(
    (contractId, contractName) => {
      confirm({
        title: t('archiveModal.title'),
        icon: <ExclamationCircleOutlined />,
        content: t('archiveModal.alert', { contractName }),
        centered: true,
        okText: t('archiveModal.yes'),
        cancelText: t('archiveModal.no'),
        okButtonProps: { type: 'primary', danger: true },
        onOk: () =>
          fileTheContract(contractId)
            .then(() => {
              mutate({
                ...contracts,
                content: contracts?.content?.filter(
                  item => item.id !== contractId,
                ),
              });

              mutateArchivedContracts({
                ...archivedContracts,
                content: [
                  ...archivedContracts.content.filter(
                    contract => contract.id !== contractId,
                  ),
                ],
              });
              message.success(t('msgs.successfullyArchived'));
            })
            .catch(({ response: { status } }) => {
              if (status === 409) {
                message.error(t('msgs.errorWhenArchivingWithDependency'));
              } else {
                message.error(t('msgs.errorWhenArchiving'));
              }
            }),
      });
    },
    [
      t,
      mutateArchivedContracts,
      archivedContracts,
      fileTheContract,
      contracts,
      mutate,
    ],
  );

  const adminActions = useCallback(
    (_, record) => (
      <>
        <Tooltip title={t('buttons.details')}>
          <Button
            icon={<FileSearchOutlined />}
            type={'link'}
            onClick={() => navigate(`/financial/contracts/${record.id}`)}
          />
        </Tooltip>
        {listingType !== 'filed' && (
          <RoleChecker role={rolesConstants.MANAGE_PLANS}>
            <Tooltip title={t('buttons.toFile')}>
              <Button
                icon={<FolderOpenOutlined />}
                type={'link'}
                danger
                onClick={() =>
                  showArchiveConfirmation(record.id, record.planDesc)
                }
              />
            </Tooltip>
          </RoleChecker>
        )}
      </>
    ),
    [navigate, t, listingType, showArchiveConfirmation],
  );

  const sharingType = useCallback(
    (value, record) => {
      if (record.payPerUseFlag === 'S') {
        return t('attributes:type.payPerUse');
      }
      const type = t(`attributes:type.${value}`);

      return t(`attributes:${type}`);
    },
    [t],
  );

  const chargingType = useCallback(
    (_, record) => {
      const operatorDiffChargeFlag = record?.operatorDiffChargeFlag;

      if (operatorDiffChargeFlag === 'S') {
        return t('attributes:chargingType.difPerOp');
      } else if (operatorDiffChargeFlag === 'N') {
        return t('attributes:chargingType.fixed');
      }
    },
    [t],
  );

  const handleHasTag = useCallback(
    value => {
      const [color, text] = value ? ['green', 'yes'] : ['red', 'no'];
      return (
        <Tag color={color}>{t(`attributes:hasTag.${text}`).toUpperCase()}</Tag>
      );
    },
    [t],
  );

  const columns = useMemo(
    () => [
      { title: 'ID', dataIndex: 'id', align: 'center' },
      {
        title: t('attributes:externalId'),
        dataIndex: 'externalId',
        align: 'center',
      },
      {
        title: t('attributes:client'),
        dataIndex: 'customerId',
        render: (_, record) => record.customer?.name,
      },
      { title: t('attributes:planDesc'), dataIndex: 'planDesc' },
      {
        title: t('attributes:type.title'),
        dataIndex: 'sharingType',
        align: 'center',
        render: sharingType,
      },
      {
        title: t('attributes:chargingType.title'),
        dataIndex: 'operatorDiffChargeFlag',
        align: 'center',
        render: chargingType,
      },
      {
        title: t('attributes:franchise'),
        dataIndex: 'franchise',
        align: 'center',
        render: value => `${value} MB`,
      },
      {
        title: t('attributes:createdAt'),
        dataIndex: 'createdAt',
        render: dateFormat,
      },
      {
        title: t('attributes:operator'),
        dataIndex: 'motOperators',
        align: 'center',
        render: value => value?.name,
      },
      {
        title: t('attributes:isca'),
        dataIndex: 'isca',
        align: 'center',
        render: handleHasTag,
      },
      listingType === 'active' && {
        title: t('attributes:nbVirtualAccounts'),
        dataIndex: 'nbVirtualAccounts',
        align: 'center',
      },
      canManage && {
        title: t('attributes:actions'),
        dataIndex: 'actions',
        align: 'center',
        render: adminActions,
      },
    ],
    [
      t,
      sharingType,
      chargingType,
      handleHasTag,
      listingType,
      canManage,
      adminActions,
    ],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: hasCustomersRoles,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:client'),
        item: (
          <SelectCustomers
            value={selectedClient ? Number(selectedClient) : undefined}
            onChange={value => handleSetSearchParams({ customerId: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:description'),
        item: (
          <Search
            placeholder={t('placeholders:description')}
            onSearch={value => handleSetSearchParams({ description: value })}
            onChange={({ target: { value } }) => setDescriptionValue(value)}
            value={descriptionValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:externalId'),
        item: (
          <Search
            placeholder={t('placeholders:externalId')}
            onSearch={value => handleSetSearchParams({ externalId: value })}
            onChange={({ target: { value } }) => setExternalIdValue(value)}
            value={externalIdValue}
            allowClear
          />
        ),
      },
    ],
    [
      t,
      handleSetSearchParams,
      hasCustomersRoles,
      selectedClient,
      descriptionValue,
      externalIdValue,
    ],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      handleSetSearchParams({
        page: paginationObj.current,
        linesPerPage: paginationObj.pageSize,
      });
    }
  };

  return (
    <>
      {modalVisible && (
        <RoleChecker role={rolesConstants.MANAGE_PLANS}>
          <NewContractModal
            visible={modalVisible}
            mutateNewContract={handleMutateContract}
            onClose={() => setModalVisible(false)}
          />
        </RoleChecker>
      )}
      <Tabs
        type="card"
        defaultActiveKey="active"
        activeKey={listingType}
        onChange={key => handleSetSearchParams({ listingType: key })}
        style={{ background: '#ffffff' }}
      >
        <TabPane key="active" tab={t('visions.active')} />
        <TabPane key="filed" tab={t('visions.filed')} />
      </Tabs>
      <Table
        title={
          <Filters
            title={t('title')}
            showFilters={showFilters}
            setShowFilters={handleShowFilters}
          />
        }
        columns={columns}
        data={data?.content}
        loading={!data?.content}
        tableKey="contracts"
        pagination={pagination}
        onChange={handleTableChange}
        extraActions={
          <RoleChecker role={rolesConstants.MANAGE_PLANS}>
            <Tooltip title={t('buttons.newContract')}>
              <Button
                icon={<PlusCircleOutlined />}
                size={'large'}
                type={'link'}
                onClick={() => setModalVisible(true)}
              />
            </Tooltip>
          </RoleChecker>
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
    </>
  );
};

export default Contracts;
