import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Tag, Tooltip, Progress } from 'antd';
import Table from '@components/Table';
import {
  bytesFormat,
  extractDateInISOFormat,
  getDateFromPartiallyInIsoFormat,
} from '@utils/formatters';

import { RiCalendarTodoFill } from 'react-icons/ri';
import { PlusCircleOutlined } from '@ant-design/icons';
import useSwr from '@hooks/useSwr';
import CustomPopover from '@src/components/CustomPopover/CustomPopover';
import BlockStatusTag from '@src/components/BlockStatusTag/BlockStatusTag';

const Diagnosis = ({ msisdn }) => {
  const { t } = useTranslation(['inventory', 'attributes']);

  const { data } = useSwr('/service-proxy/inventory/MOT/diagnostico-simcard', {
    msisdn,
  });

  const columnFranchise = useCallback(
    (value, record) => {
      const { simcardCredit, sharedCredit } = record;

      const dataContentPopover = [
        {
          title: t('franchise.sim-card'),
          value: `+ ${simcardCredit} MB`,
          color: 'green',
          show: simcardCredit,
        },
        {
          title: t('franchise.contract'),
          value: `+ ${sharedCredit} MB`,
          color: 'blue',
          show: sharedCredit,
        },
      ];

      const sizeFranchising = bytesFormat(value * 1024 * 1024);

      return (
        <div>
          {value && value !== 0 && (
            <>
              <span style={{ margin: 0, fontSize: '0.86rem' }}>
                {sizeFranchising}
              </span>
              {(sharedCredit && sharedCredit !== 0) ||
              (simcardCredit && simcardCredit !== 0) ? (
                <CustomPopover
                  title={t('table.franchising')}
                  titleValue={!value ? '' : `${value} MB`}
                  titleContent={t('franchise.additional')}
                  dataContent={dataContentPopover.filter(
                    item => item.show !== 0 && item.show,
                  )}
                >
                  <Button type="link" style={{ padding: 0, marginLeft: 6 }}>
                    <PlusCircleOutlined />
                  </Button>
                </CustomPopover>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      );
    },
    [t],
  );

  const columnConsumption = useCallback(
    (value, record) => {
      const {
        consumptionTotal,
        consumptionCurrentDay,
        consumptionPercent,
        consumptionValidated,
        simcardCredit,
      } = record;

      const percent = consumptionPercent;

      const percentValidated =
        consumptionValidated && consumptionTotal
          ? (Number(consumptionValidated) * consumptionPercent) /
              consumptionTotal +
            (simcardCredit || 0)
          : 0;

      let strokeColorDark = '#27B502';
      let strokeColor = '#2FD105';
      if (percent >= 80 && percent <= 100) {
        strokeColorDark = '#EA9801';
        strokeColor = '#ffa500';
      } else if (percent > 100) {
        strokeColorDark = '#CD022A';
        strokeColor = '#EC0231';
      }

      const sizeConsumption = bytesFormat(value);
      const sizeConsumptionCurrentDay = bytesFormat(consumptionCurrentDay);
      const sizeConsumptionValidated = bytesFormat(consumptionValidated);
      const sizeFranchising = bytesFormat(
        (record.franchise + (simcardCredit || 0)) * 1024 * 1024,
      );
      const dataContentPopover = [
        {
          title: t('consumption.validated'),
          value: sizeConsumptionValidated,
          color: strokeColor,
          show: consumptionValidated,
        },
        {
          title: t('consumption.daily'),
          value: sizeConsumptionCurrentDay,
          color: strokeColorDark,
          show: consumptionCurrentDay,
        },
      ];

      return (
        <div className="col-line-consumption">
          <p style={{ margin: 0, fontSize: '0.86rem' }}>
            {percent && percent !== 0
              ? `${sizeConsumption} / ${sizeFranchising}`
              : sizeConsumption}
          </p>
          {percent !== 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Tooltip
                title={`${
                  percent && percent >= 1
                    ? Math.trunc(percent)
                    : percent?.toFixed(2)
                }%`}
              >
                <Progress
                  percent={percent}
                  trailColor="#fdfdfd"
                  showInfo={false}
                  strokeColor={strokeColorDark}
                  success={{
                    percent: percentValidated,
                    strokeColor,
                  }}
                  className="progress-bar-consumption"
                />
              </Tooltip>

              <CustomPopover
                title={t('table.consumption')}
                titleValue={sizeConsumption}
                titleContent=""
                dataContent={dataContentPopover.filter(
                  item => item.show !== 0 && item.show,
                )}
              >
                <Button
                  type="link"
                  style={{ padding: 0, margin: 0, marginLeft: 6 }}
                >
                  <PlusCircleOutlined />
                </Button>
              </CustomPopover>
            </div>
          )}
        </div>
      );
    },
    [t],
  );

  const handleSuspensionDate = useCallback((record, text) => {
    const initialDate = getDateFromPartiallyInIsoFormat(
      record.suspensionInitialDate,
    );
    const finalDate = getDateFromPartiallyInIsoFormat(
      record.suspensionFinalDate,
    );
    if (initialDate || finalDate) {
      const date = `${text ? `${text}: ` : ''}${initialDate ?? ''}${
        finalDate ? ` - ${finalDate}` : ''
      }`;
      return (
        <Tooltip title={date}>
          <RiCalendarTodoFill
            style={{
              margin: 2,
              opacity: 0.4,
              color: 'green',
              cursor: 'pointer',
              fontSize: 18,
              marginBottom: '-4px',
            }}
          />
        </Tooltip>
      );
    }
  }, []);
  const columns = useMemo(
    () => [
      {
        title: t('table.customerName'),
        dataIndex: 'customerName',
        align: 'center',
      },
      {
        title: t('table.status'),
        dataIndex: 'status',
        align: 'center',
        render: (status, record) => (
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <Tag color={status ? t(`status:colors.${status}`) : 'red'}>
              {t(`status:${status}`).toUpperCase()}
            </Tag>
            {!status && handleSuspensionDate(record)}
          </div>
        ),
      },
      {
        title: t('table.consumption'),
        dataIndex: 'consumptionTotal',
        align: 'center',
        render: (value, row) =>
          value ? columnConsumption(value, row) : undefined,
      },
      {
        title: t('table.franchise'),
        dataIndex: 'franchise',
        align: 'center',
        render: (value, row) =>
          value ? columnFranchise(value, row) : undefined,
      },
      {
        title: t('attributes:automaticBlocking.title'),
        dataIndex: 'automaticBlocking',
        align: 'center',
        render: value => {
          const [color, text] = value
            ? ['green', 'activated']
            : ['red', 'disabled'];

          return (
            <Tag color={color}>
              {t(`attributes:automaticBlocking.${text}`).toUpperCase()}
            </Tag>
          );
        },
      },
      {
        title: t('table.networkBlockStatus'),
        dataIndex: 'networkBlockStatus',
        align: 'center',
        render: (value, record) => (
          <BlockStatusTag
            blockType={value}
            blockingInitialDate={record.networkBlockStartDate}
            blockingFinalDate={record.networkBlockEndDate}
          />
        ),
      },
      {
        title: t('table.networkBlockEndDate'),
        dataIndex: 'networkBlockEndDate',
        align: 'center',
        render: extractDateInISOFormat,
      },
      {
        title: t('table.networkBlockStartDate'),
        dataIndex: 'networkBlockStartDate',
        align: 'center',
        render: extractDateInISOFormat,
      },
      {
        title: t('table.suspensionEndDate'),
        dataIndex: 'suspensionEndDate',
        align: 'center',
        render: extractDateInISOFormat,
      },
      {
        title: t('table.suspensionStartDate'),
        dataIndex: 'suspensionStartDate',
        align: 'center',
        render: extractDateInISOFormat,
      },
      {
        title: t('table.cancellationDate'),
        dataIndex: 'cancellationDate',
        render: extractDateInISOFormat,
      },
    ],
    [t, columnFranchise, handleSuspensionDate],
  );

  return (
    <>
      <Table
        columns={columns}
        data={data}
        customRowKey="id"
        loading={!data}
        title={t('diagnosis.title')}
        tableKey="diagnosis"
        pagination={false}
      />
    </>
  );
};

export default Diagnosis;
