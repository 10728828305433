import i18next from 'i18next';
import { parse, format } from 'date-fns';
import moment from 'moment';

export const formatCpfCnpj = value => {
  const cnpjCpf = value ? value.replace(/\D/g, '') : '';
  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5',
  );
};

export const parseAndFormatDate = (string, sourceFormat, destinationFormat) => {
  try {
    return format(parse(string, sourceFormat, new Date()), destinationFormat);
  } catch {
    return undefined;
  }
};

export const formatDate = (date, destinationFormat) => {
  return format(date, destinationFormat);
};

export const formatDateTime = dateString => {
  const data = new Date(dateString);

  data.setHours(data.getHours() - 3);

  function pad(num) {
    return num.toString().padStart(2, '0');
  }

  const day = pad(data.getDate());
  const month = pad(data.getMonth() + 1);
  const year = data.getFullYear();
  const hour = pad(data.getHours());
  const minutes = pad(data.getMinutes());

  const formatedHouder = `${day}/${month}/${year} ${hour}:${minutes}`;

  return formatedHouder;
};

export const dateFormat = date => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj.toLocaleString(i18next.language);
  }
  return '';
};

// 2011-10-05 -> 05/10/2011
export const getDateFromPartiallyInIsoFormat = date => {
  if (!date) {
    return undefined;
  }
  date = date.split('-').reverse();
  return date.join('/');
};

// 2011-10-05T14:48:00.000Z -> 20111005
export const getDateInISOFormat = date => {
  const newDate = date?.split('T')?.[0];
  return newDate?.replaceAll('-', '');
};

// 2011-10-05T14:48:00.000Z -> 05/10/2011
export const extractDateInISOFormat = date => {
  const dateSplit = date?.split('T')?.[0];
  return getDateFromPartiallyInIsoFormat(dateSplit ?? date);
};

//  20111005 --> 2011-10-05T14:48:00.000Z
export const getDateWithISOFormat = (date, startOrEnd) => {
  const newDate = moment(date, 'YYYYMMDD');
  const isEndData = startOrEnd === 'end';
  newDate.set({
    hour: isEndData ? 23 : 0,
    minute: isEndData ? 59 : 0,
    second: isEndData ? 59 : 0,
    millisecond: isEndData ? 999 : 0,
  });
  return newDate?.toISOString();
};

// DD/MM/YYYY --> YYYYMMDD
export const getDateFromBRPattern = date => date?.split('/').reverse().join('');

// YYYYMMDD --> DD/MM/YYYY
export const getDateToBRPattern = date =>
  date &&
  getDateWithISOFormat(date).split('T')[0].split('-').reverse().join('/');

export const getDateToUSPatternWithDivider = date =>
  date && getDateInISOFormat(date).split('T')[0]?.split('-')?.join('/');

export const getDateToUsPattern = date =>
  date && getDateWithISOFormat(date).split('T')[0];

export const bytesToMbytes = (bytes, fixed = 3) => {
  return (bytes * 9.5367431640625 * Math.pow(10, -7)).toFixed(fixed);
};

export const mbytesToBytes = (mb, fixed = 3) => {
  return (mb * 1048576).toFixed(fixed);
};

export function maskPhone(value) {
  value = value.replace(/\D/g, '');

  if (value.length > 10) {
    value[0] === '0';
    value =
      value[0] === '0'
        ? value.replace(/^(\d{3})(\d)/g, '($1) $2')
        : value.replace(/^(\d{2})(\d)/g, '($1) $2');
  } else {
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  }

  value = value.replace(/(\d)(\d{4})$/, '$1-$2');

  return value;
}

export const getTomorrowDate = () => {
  const tomorrow = new Date(new Date());
  tomorrow.setDate(tomorrow.getDate() + 1);

  return tomorrow
    .toLocaleString(i18next.language)
    .split(' ')[0]
    .split('/')
    .reverse()
    .join('-');
};

// Conversion:: Seconds string > HH:mm:ss
export const hourFormat = text => {
  if (text) {
    const textn = parseInt(text, 10);

    const hours = Math.floor(textn / 3600);
    const minutes = Math.floor((textn % 3600) / 60);
    const seconds = textn % 60;

    const str = [hours, minutes, seconds]
      .map(element => {
        return String(element).padStart(2, '0');
      })
      .join(':');
    return str;
  }
  return '';
};

export const formatCurrency = value =>
  value?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

export const formatBRNumber = value => value?.toLocaleString('pt-BR');

export const bytesFormat = str => {
  const units = ['', 'K', 'M', 'G', 'T'];
  let unit = 0;

  let strn = parseInt(str ?? 0, 10);
  while (strn > 1024) {
    strn /= 1024;
    unit += 1;
  }
  if (strn >= 1000) {
    strn = Math.trunc(strn / 1000);
    unit += 1;
  }
  return `${Math.trunc(strn * 100) / 100} ${units[unit]}B`;
};

export const firstLetterUpper = str =>
  str[0].toUpperCase() + str.substr(1).toLowerCase();

export const leaveOnlyNumbers = number =>
  Number(number?.toString()?.replace(/\D+/g, ''));

const loadImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', err => reject(err));
    image.src = url;
  });

export const getBase64Image = (src, cb) => {
  loadImage(src).then(image => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0);
    cb(canvas.toDataURL('image/png'));
  });
};

// object obtained from Google API geocode
// [
//     {
//       long_name: '',
//       short_name: '',
//       types: [''],
//     },
// ]
export const formatAddressFromGeoCode = addressComponents => {
  if (!addressComponents) return;
  // types
  const allNecessaryInfoTypes = [
    'administrative_area_level_2',
    'administrative_area_level_1',
    'postal_code',
    'country',
  ];

  // [[short_name, ...types]]
  const treatedComponents = addressComponents.reduce(
    (old, current) => [...old, [current.short_name, ...current.types]],
    [],
  );

  const data = [];
  allNecessaryInfoTypes.forEach(type => {
    treatedComponents?.forEach(trtComp => {
      if (trtComp.includes(type)) {
        data.push(trtComp[0]);
      }
    });
  });
  // 'City, state, postal code, country'
  return data.join(', ');
};

// i Thu Feb 24 2022 16:58:22 GMT-0300 (Brasilia Standard Time)
// --->
// { firstDay: "20210201", lastDay: "20210228" }
export const handleFirstAndLastDayOfMonth = date => {
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  const month = date.getMonth(); // 0 = January
  const year = date.getFullYear();
  const d = new Date(date.getFullYear(), month + 1, 0);
  const firstDay = `${year}${months[d.getMonth()]}01`;
  const lastDay = `${year}${months[d.getMonth()]}${d.getDate()}`;

  return {
    firstDay,
    lastDay,
  };
};

// { ' a  ': 'test' } ---> { a: 'test' }
export const trimInObjectKeys = obj =>
  Object.entries(obj).reduce((acc, curr) => {
    let [key, value] = curr;
    // Checking if the key is a string
    acc[typeof key === 'string' ? key.trim() : key] = value;
    return acc;
  }, {});

export const convertFileInData64 = (file, callbackData64) => {
  const reader = new FileReader();
  reader.onload = () => {
    const imageUrl = reader.result;
    callbackData64(imageUrl);
  };
  reader.readAsDataURL(file);
};

export const convertImgInFile = async (url, filename, callbackFile) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], filename, { type: blob.type });

    callbackFile(file);
  } catch (error) {
    console.error(error);
  }
};

export const formatLongText = (text, characterLimit) => {
  const isLongText = text?.length > characterLimit;
  return isLongText ? `${text.slice(0, characterLimit)}...` : text;
};

export const unixTimestampFormat = date => {
  if (date) {
    const dateObj = new Date(date * 1000);
    return dateObj.toLocaleString(i18next.language);
  }
  return '';
};
