import React, { useState, useCallback, useContext } from 'react';
import { Button, Spin, notification, message } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import apiMiddleware from '@src/services/apiMiddleware';
import { useNavigate } from 'react-router-dom';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';

const antIcon = (
  <LoadingOutlined
    size={'small'}
    style={{ color: 'white', marginLeft: 10 }}
    spin
  />
);

function CsvDetailedInvoice({ customerId, invoiceId }) {
  const { t } = useTranslation(['invoices', 'reports']);
  const { keycloak } = useContext(KeycloakContext);
  const locale = keycloak?.idTokenParsed?.locale;

  const userLanguage = locale ?? 'pt';
  const navigate = useNavigate();

  const [downloadProgress, setDownloadProgress] = useState(false);

  const navigateToReports = useCallback(() => {
    message.destroy('report-requested');
    navigate(`/management/reports?type=broker_invoice`);
  }, [navigate]);

  const handleGetDetailedInvoice = useCallback(async () => {
    setDownloadProgress(true);
    try {
      await apiMiddleware
        .get(
          `/service-proxy/broker/${customerId}/invoices/${invoiceId}/detailed?locale=${userLanguage}`,
        )
        .then(() => {
          message.success({
            content: (
              <span>
                {t('reports:request.report-success')}
                <Button
                  onClick={navigateToReports}
                  type="link"
                  style={{ padding: 3 }}
                >
                  {t('reports:request.go-to-reports')}
                </Button>
                {t('reports:request.check-report-progress-and-download')}
              </span>
            ),
            key: 'report-requested',
            duration: 5,
          });
        });
    } catch (e) {
      notification.error({ description: t('errors.exporting') });
    }
    setDownloadProgress(false);
  }, [customerId, invoiceId, t, navigateToReports, userLanguage]);

  return (
    <Button
      icon={<DownloadOutlined />}
      style={{ marginRight: '1rem' }}
      onClick={handleGetDetailedInvoice}
      type={'primary'}
    >
      {downloadProgress ? <Spin indicator={antIcon} /> : t('csvExport')}
    </Button>
  );
}

export default CsvDetailedInvoice;
