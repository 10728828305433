import { useMemo, useCallback, useEffect, useState } from 'react';

const useUserPreferences = userId => {
  const [preferences, setPreferences] = useState();

  useEffect(() => {
    const storageData = localStorage.getItem('userPreferences');
    if (storageData) {
      setPreferences(JSON.parse(storageData));
    }
  }, []);

  const user = useMemo(() => `user_${userId}`, [userId]);

  const userPreferences = useMemo(
    () => preferences?.[user],
    [preferences, user],
  );

  const userPreferencesTables = useMemo(
    () => preferences?.[user]?.tables,
    [preferences, user],
  );

  const getTablePreferences = useCallback(
    tableName => userPreferencesTables?.[tableName],
    [userPreferencesTables],
  );

  const setTablePreferences = useCallback(
    (
      tableName,
      { columnPreferences, activeFilters, size, newSelectedFilters },
    ) => {
      const tablePreferences = getTablePreferences?.(tableName);
      const newPreferences = {
        ...preferences,
        [user]: {
          ...userPreferences,
          tables: {
            ...userPreferencesTables,
            [tableName]: {
              size: size ?? tablePreferences?.size,
              columnPreferences:
                columnPreferences ?? tablePreferences?.columnPreferences,
              activeFilters: activeFilters ?? tablePreferences?.activeFilters,
              filterSelected: newSelectedFilters,
            },
          },
        },
      };
      localStorage.removeItem('userPreferences');
      localStorage.setItem('userPreferences', JSON.stringify(newPreferences));
      // @ts-ignore
      setPreferences(newPreferences);
    },
    [
      getTablePreferences,
      preferences,
      user,
      userPreferences,
      userPreferencesTables,
    ],
  );

  return {
    setTablePreferences,
    getTablePreferences,
  };
};

export default useUserPreferences;
