import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import {
  Table,
  Button,
  Menu,
  Dropdown,
  Tooltip,
  Input,
  DatePicker,
  ConfigProvider,
} from 'antd';

import {
  ColumnHeightOutlined,
  SearchOutlined,
  UndoOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import ModalSelectColumns from '../ModalSelectColumns';
import { Card } from '../ui';
import 'moment/locale/pt-br';
import { parseAndFormatDate } from '@utils/formatters';
import { Container } from './styles';
import { getAntLocale } from '@utils/locales';
import SessionContext from '@src/store/SessionContext/SessionContext';
import { QuerySelectOptions } from '../QueryBuilder/QuerySelectOptions/index';

const { Search } = Input;
const { RangePicker } = DatePicker;

const ParametrizedTable = ({
  columns,
  data,
  loading,
  title,
  tableKey,
  onChange,
  footer,
  pagination,
  onRow,
  selectable,
  setSelectedRowKeys: setParentSelectedRowKeys,
  extraActions,
  className,
  extraFilters,
  tabsFilters,
  primaryColor,
  tableStyle,
  maxHeight,
  mainExtraAction,
  rowClassName,
  queryFilters,
  allColumns,
  rowKey,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('table');

  const {
    userPreferences: { setTablePreferences, getTablePreferences },
  } = useContext(SessionContext);

  const [modalColumnsVisible, setModalColumnsVisible] = useState(false);
  const [tableSize, setTableSize] = useState('middle');
  const columSearchRef = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableRefKey, setTableRefKey] = useState(0);
  const [tableIsFiltered, setTableIsFiltered] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(
    queryFilters?.itemsToMap.map(item => item.name),
  );
  const [formattedColumns, setFormattedColumns] = useState();

  const removeDuplicates = array => {
    const uniqueObjects = {};
    const resultArray = [];
    array?.forEach(obj => {
      const dataIndex = obj?.dataIndex;
      if (!uniqueObjects[dataIndex]) {
        uniqueObjects[dataIndex] = true;
        resultArray?.push(obj);
      }
    });

    return resultArray;
  };

  const handleSearch = (_, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters, setSelectedKeys) => {
    clearFilters();
    setSelectedKeys([]);
  };

  const handleDateChange = (dateString, setSelectedKeys, confirm) => {
    if (dateString[0] !== '') {
      const start = parseAndFormatDate(dateString[0], 'dd/MM/yyyy', 'yyyyMMdd');
      const end = parseAndFormatDate(dateString[1], 'dd/MM/yyyy', 'yyyyMMdd');
      // TODO: format date filter
      setSelectedKeys([{ start, end }]);
    } else {
      setSelectedKeys([]);
    }
    confirm();
  };

  const getColumnDateSearchProps = useCallback(
    () => ({
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <ConfigProvider locale={getAntLocale(language)}>
            <RangePicker
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              allowClear
              onChange={(_, dateString) =>
                handleDateChange(dateString, setSelectedKeys, confirm)
              }
            />
          </ConfigProvider>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1DA57A' : undefined }} />
      ),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(
            () => columSearchRef.current && columSearchRef.current.select(),
            100,
          );
        }
      },
    }),
    [language],
  );

  const getColumnStringSearchProps = useCallback(
    column => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            ref={columSearchRef}
            placeholder={t('search')}
            prefix={column.stringFilterPrefix}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            suffix={column.stringFilterSuffix}
            value={selectedKeys[0]}
            allowClear
            enterButton
            onChange={e => {
              if (!e.target.value) {
                handleReset(clearFilters, setSelectedKeys);
              }
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onSearch={() => handleSearch(selectedKeys, confirm)}
          />
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1DA57A' : undefined }} />
      ),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(
            () => columSearchRef.current && columSearchRef.current.select(),
            100,
          );
        }
      },
    }),
    [t],
  );

  useEffect(() => {
    const tablePreferences = getTablePreferences(tableKey);
    const columnPreferences = tablePreferences?.columnPreferences;
    const tableSize = tablePreferences?.size;
    const userSelectedFilters = tablePreferences?.filterSelected;
    setSelectedFilters(userSelectedFilters);

    if (tableSize) {
      setTableSize(tableSize);
    }
    if (columnPreferences) {
      const sortedColumns = [...removeDuplicates(columns)]
        .sort((a, b) => {
          const indexA = columnPreferences?.order?.indexOf(`${a.dataIndex}`);
          const indexB = columnPreferences?.order?.indexOf(`${b.dataIndex}`);
          if (indexA === -1 && indexB === -1) {
            return 0;
          } else if (indexA === -1) {
            return 1;
          } else if (indexB === -1) {
            return -1;
          }

          return indexA - indexB;
        })
        ?.map(col => ({
          ...col,
          visible:
            columnPreferences?.visibility?.includes(`${col.dataIndex}`) ||
            !columnPreferences?.allColumnsToGet?.includes(`${col.dataIndex}`),
          ...(col.stringFiltered && getColumnStringSearchProps(col)),
          ...(col.dateFiltered && getColumnDateSearchProps()),
        }));

      setFormattedColumns(sortedColumns);
    } else {
      setFormattedColumns(
        removeDuplicates(columns)?.map(col => ({
          ...col,
          visible: true,
        })),
      );
    }
  }, [
    columns,
    getTablePreferences,
    tableKey,
    getColumnDateSearchProps,
    getColumnStringSearchProps,
    allColumns,
  ]);

  const handleUpdateFilters = useCallback(
    newSelectedFilters => {
      setTablePreferences(tableKey, { newSelectedFilters });
    },
    [setTablePreferences, tableKey],
  );

  const handleUpdateTableSize = useCallback(
    ({ key }) => {
      setTablePreferences(tableKey, { size: key });
    },
    [setTablePreferences, tableKey],
  );

  const tableSizeMenu = useMemo(
    () => (
      <Menu
        selectedKeys={tableSize && [tableSize]}
        onClick={handleUpdateTableSize}
      >
        {['small', 'middle', 'large'].map(item => (
          <Menu.Item key={item}>{t(`line-heights.${item}`)}</Menu.Item>
        ))}
      </Menu>
    ),
    [handleUpdateTableSize, tableSize, t],
  );

  const onSelectedRowsChange = (newSelectedRowKeys, newSelectedRows) => {
    setParentSelectedRowKeys(newSelectedRowKeys, newSelectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowsChange,
  };

  const handleResetAllFilters = useCallback(() => {
    setTableRefKey(key => key + 1);
    setTableIsFiltered(false);
    if (onChange) {
      onChange(pagination, {});
    }
  }, [onChange, pagination]);

  const handleTableChange = (paginationConf, filters) => {
    const isFiltered = Object.values(filters).some(x => x !== null && x !== '');
    setTableIsFiltered(isFiltered);
    if (onChange) {
      onChange(paginationConf, filters);
    }
  };

  const handleUpdateColumns = useCallback(
    newColumns => {
      const tablePreferences = getTablePreferences(tableKey);
      const visibility = [];
      const allColumnsToGet = removeDuplicates(allColumns || columns);
      const order = newColumns?.map(col => {
        if (col.visible) {
          visibility.push(col?.dataIndex);
        }
        return col?.dataIndex;
      });

      const missingColumns = allColumnsToGet?.filter(
        item =>
          !newColumns.find(secondItem => secondItem?.title === item?.title),
      );

      const missingColumnsOrder = missingColumns
        ?.filter(item =>
          tablePreferences?.columnPreferences?.order?.includes(item?.dataIndex),
        )
        .map(item => item?.dataIndex);
      const missingColumnsVisibily = missingColumns
        ?.filter(item =>
          tablePreferences?.columnPreferences?.visibility?.includes(
            item?.dataIndex,
          ),
        )
        ?.map(item => item?.dataIndex);
      const arrayWithDataIndex = missingColumnsOrder?.map(item => {
        return {
          dataIndex: item,
          index: tablePreferences?.columnPreferences?.order?.findIndex(
            secondItem => secondItem === item,
          ),
        };
      });

      arrayWithDataIndex.forEach(({ dataIndex, index }) => {
        order.splice(index, 0, dataIndex);
      });

      const allColumsnOnlyDataIndex = allColumnsToGet?.map(
        item => item?.dataIndex,
      );
      const newOrder = allColumsnOnlyDataIndex?.sort((a, b) => {
        const indexA = order ? order?.indexOf(a) : -1;
        const indexB = order ? order?.indexOf(b) : -1;
        if (indexA === -1 && indexB === -1) {
          return 0;
        } else if (indexA === -1) {
          return 1;
        } else if (indexB === -1) {
          return -1;
        }

        return indexA - indexB;
      });

      const columnPreferences = {
        allColumnsToGet: allColumsnOnlyDataIndex,
        order: newOrder,
        visibility: missingColumnsVisibily
          ? [...visibility, ...missingColumnsVisibily]
          : visibility,
      };
      setTablePreferences(tableKey, { columnPreferences });
    },
    [setTablePreferences, tableKey, allColumns, getTablePreferences, columns],
  );

  const tableColumnsWidth = useMemo(() => {
    let sumColumnsWidth = 0;
    formattedColumns?.forEach(object => {
      if (!object.title) {
        sumColumnsWidth += 40;
      } else {
        sumColumnsWidth += object.title.length * 20;
      }
    });
    return sumColumnsWidth;
  }, [formattedColumns]);

  return (
    <Container>
      <ModalSelectColumns
        columns={formattedColumns}
        updateColumns={handleUpdateColumns}
        visible={modalColumnsVisible}
        onClose={() => setModalColumnsVisible(false)}
      />
      <Card
        bodyStyle={{ padding: 0 }}
        extra={
          <div className={'card-content'}>
            {extraActions}
            {tableIsFiltered && (
              <Button
                icon={<UndoOutlined />}
                size={'large'}
                type={'link'}
                onClick={handleResetAllFilters}
              >
                {t('reset-filters')}
              </Button>
            )}
            <Dropdown overlay={tableSizeMenu}>
              <Tooltip placement={'top'} title={t('line-height-settings')}>
                <Button
                  icon={<ColumnHeightOutlined />}
                  size={'large'}
                  type={'link'}
                  style={{ color: primaryColor }}
                />
              </Tooltip>
            </Dropdown>
            <Tooltip placement={'top'} title={t('columns-settings')}>
              <Button
                icon={<SettingOutlined />}
                size={'large'}
                type={'link'}
                style={{ color: primaryColor }}
                onClick={() => setModalColumnsVisible(true)}
              />
            </Tooltip>
            {mainExtraAction}
          </div>
        }
        title={
          <>
            {title}
            {queryFilters?.showFilters && (
              <QuerySelectOptions
                queryFilters={queryFilters}
                handleUpdateFilters={handleUpdateFilters}
                selectedFilters={
                  selectedFilters ||
                  queryFilters?.itemsToMap.map(item => item.name)
                }
              />
            )}
          </>
        }
      >
        {extraFilters}
        {tabsFilters}
        <ConfigProvider locale={getAntLocale(language)}>
          <Table
            key={tableRefKey}
            className={`${className} table--striped`}
            columns={formattedColumns?.reduce((acc, e) => {
              // If something else that is not a string, width attribute is necessary
              if (typeof e.title === 'string') {
                e.width = e.width ?? `${e.title.length + 5}ch`;
              }
              if (e.visible === true) {
                // If something else that is not a string, width attribute is necessary
                if (e.title && typeof e.title === 'string') {
                  e.width = e.width ?? `${e.title.length + 5}ch`;
                }
                return [...acc, e];
              }
              return acc;
            }, [])}
            align="center"
            dataSource={data}
            loading={loading}
            footer={footer}
            pagination={pagination}
            rowClassName={rowClassName}
            rowKey={record => (rowKey ? record[rowKey] : record['id'])}
            rowSelection={selectable ? rowSelection : undefined}
            scroll={{
              x: data?.length > 0 ? 'max-content' : tableColumnsWidth,
              y: maxHeight,
            }}
            sticky
            size={tableSize}
            style={tableStyle}
            tableLayout="auto"
            onChange={handleTableChange}
            onRow={onRow}
          />
        </ConfigProvider>
      </Card>
    </Container>
  );
};

export default ParametrizedTable;
